import React, { SyntheticEvent, useEffect, useState } from "react";
import "./PurchaseCard.scss";
import { CardPropsType } from "commons/types";
import { CardMedia, Grid, Typography } from "@mui/material";

export const PurchaseCard = ({
    title,
    imgUrl,
    imgHeight,
    bottom_title,
    bottom_sub_title,
}: CardPropsType) => {
    return (
        <Grid
            container
            boxShadow={"0px 0px 14px rgba(0, 148, 255, 0.38)"}
            sx={{ padding: "10%", backgroundColor: "#ffffff" }}
            display={"flex"}
            direction={"column"}
            justifyContent={"space-between"}
            marginBottom={10}
            height={470}
            className="purchase-cardbody"
        >
            <Grid item sx={{ minHeight: "150px" }}>
                <Typography
                    variant="body1"
                    fontSize={"30px"}
                    color={"#2457a0"}
                    textAlign={"left"}
                    width={"100%"}
                    fontWeight={700}
                    className="card-title"
                >
                    {title}
                </Typography>
                <CardMedia
                    sx={{ backgroundColor: "#F9FBFC" }}
                    component="img"
                    height={`${imgHeight}px`}
                    style={{ width: "auto", marginTop: "20px" }}
                    image={imgUrl}
                    alt="icon"
                />
            </Grid>
            <Grid item sx={{ minHeight: "150px" }} className="card-grid">
                {bottom_title ? (
                    <Typography
                        variant="body1"
                        fontSize={"32px"}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"100%"}
                        fontWeight={400}
                        className="sub-title"
                    >
                        {bottom_title}
                    </Typography>
                ) : (
                    <Typography
                        variant="body1"
                        fontSize={"30px"}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"100%"}
                        fontWeight={400}
                        className="sub-title"
                    ></Typography>
                )}
                {bottom_sub_title ? (
                    <Typography
                        variant="body1"
                        fontSize={"18px"}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"85%"}
                        fontWeight={400}
                        className="bottom-sub-title"
                    >
                        {bottom_sub_title}
                    </Typography>
                ) : (
                    <Typography
                        variant="body1"
                        fontSize={"32px"}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"100%"}
                        fontWeight={700}
                        className="bottom-sub-title"
                    ></Typography>
                )}
            </Grid>
        </Grid>
    );
};
