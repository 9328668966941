import { PageGeneralTexts } from "commons/enums";
import { ContactUs as ContactUsModel } from "Models/ContactUs.model";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./ContactUs.scss";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
    Box,
    Grid,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    Tab,
    TextField,
    Typography,
} from "@mui/material";
import { ContactUsService } from "services/api/ContactUsService.service";
import { ModalStyle } from "commons/GlobalModalStyle";
import { MessageSend } from "./MessageSend/MessageSend";
import * as yup from "yup";
import { E164_PHONE_REGEX } from "commons/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { subjectOptions } from "./constants";
import { ArrowSvgIcon } from "./ArrowIcon/ArrowSvgIcon";
import Button from "@mui/material/Button";

export const ContactUs = () => {
    const [open, setOpen] = useState(false);
    const [sendButtonState, setSendButtonState] = useState(false);
    const [selectOpenedState, setSelectOpenedState] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = ModalStyle;

    const contactUsSchema = yup.object().shape({
        subject: yup.string().required(),
        request: yup.string().required(),
        phone: yup.string().matches(E164_PHONE_REGEX).required(),
        email: yup.string().email().required(),
        createdTimestamp: yup.number().default(Date.now()),
    });

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(contactUsSchema) });

    const sendMessage = async (form: ContactUsModel) => {
        const result = await ContactUsService.getInstance().sendMessage(form);
        if (typeof result === "object" && "message" in result) {
            toast.success(result.message);
            handleOpen();
            reset();
        }
        if (typeof result === "string") {
            toast.error(result);
        }
    };

    const onSubmit = async (form: ContactUsModel) => {
        setSendButtonState(true);
        await sendMessage(form);
        setSendButtonState(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Grid className="contact-us-page" container xs={12} sx={{ backgroundColor: "#F1F9FE" }}>
                <Grid item xs={12}>
                    <Box>
                        <img
                            style={{ width: "100%", backgroundSize: "cover" }}
                            src="images/main-banner.png"
                            alt="banner"
                        />
                    </Box>
                </Grid>
                <Grid
                    container
                    xs={10}
                    display={"flex"}
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"left"}
                    sx={{ margin: "auto" }}
                >
                    <Typography
                        variant="h1"
                        fontSize={"70px"}
                        margin={0}
                        color={"#2457a0"}
                        textAlign={"left"}
                        mb={0}
                        className="contact-title"
                    >
                        {PageGeneralTexts.CONTACT_US}
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={10}
                    display={"flex"}
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"left"}
                    sx={{ margin: "auto", marginTop:"50px" }}
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "80%",
                                marginBottom: 5,
                            }}
                            className="subject_field"
                        >
                            <Typography
                                variant="body1"
                                fontSize={"24px"}
                                margin={0}
                                color={"#2457a0"}
                                textAlign={"left"}
                                mb={1}
                                fontWeight={700}
                                className="textfield"
                            >
                                {PageGeneralTexts.REQUEST_SUBJECT}
                            </Typography>
                            <Controller
                                control={control}
                                name="subject"
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Select
                                        displayEmpty
                                        onChange={(
                                            e: SelectChangeEvent<string>
                                        ) => onChange(e.target.value)}
                                        onBlur={onBlur}
                                        sx={{
                                            backgroundColor: "white",
                                            textAlign: "left",
                                            color: "#2457a0",
                                            border: "2px solid #2457a0",
                                            borderRadius: "5px",
                                            height: "60px",
                                        }}
                                        className={
                                            errors.subject ? "error subjectSelect" : " subjectSelect"
                                        }
                                        value={value || ""}
                                        ref={ref}
                                        onClick={() => {
                                            setSelectOpenedState(
                                                !selectOpenedState
                                            );
                                        }}
                                        IconComponent={ArrowSvgIcon}
                                    >
                                        <MenuItem
                                            disabled
                                            selected={true}
                                            value=""
                                        >
                                            <Typography
                                                variant="body1"
                                                fontSize={"24px"}
                                                margin={0}
                                                color={"#2457A0"}
                                                textAlign={"left"}
                                                className="textfield"
                                            >
                                                {
                                                    PageGeneralTexts.REQUEST_SUBJECT
                                                }
                                            </Typography>
                                        </MenuItem>
                                        {subjectOptions.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                value={option.label}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            ></Controller>
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "80%",
                                marginBottom: 5,
                            }}
                            className="subject_field"
                        >
                            <Typography
                                variant="body1"
                                fontSize={"24px"}
                                margin={0}
                                color={"#2457a0"}
                                textAlign={"left"}
                                mb={1}
                                fontWeight={700}
                                className="textfield"
                            >
                                {PageGeneralTexts.YOUR_REQUEST}
                            </Typography>

                            <TextField
                                variant="outlined"
                                className={errors.request ? "error textfield" : "textfield"}
                                rows={4}
                                multiline
                                maxLength={500}
                                placeholder="Type your request"
                                {...register("request")}
                            />
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            display={"flex"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            width={"80%"}
                            marginBottom={5}
                            className="subject_field"
                        >
                            <Grid container xs={12} md={5.8} className="phone_grid">
                                <Grid
                                    container
                                    xs={12}
                                    display={"flex"}
                                    direction={"column"}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#2457a0"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={700}
                                        className="textfield"
                                    >
                                        {PageGeneralTexts.PHONE_NUMBER}
                                    </Typography>
                                    <TextField
                                        className={errors.phone ? "error textfield" : "textfield"}
                                        variant="outlined"
                                        placeholder="Phone Number"
                                        {...register("phone")}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12} md={5.8}>
                                <Grid
                                    container
                                    xs={12}
                                    display={"flex"}
                                    direction={"column"}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#2457a0"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={700}
                                        className="textfield"
                                    >
                                        {PageGeneralTexts.EMAIL}
                                    </Typography>
                                    <TextField
                                        className={errors.email ? "error textfield" : "textfield"}
                                        variant="outlined"
                                        placeholder="Email"
                                        {...register("email")}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            display={"flex"}
                            direction={"row"}
                            justifyContent={"flex=end"}
                            width={"80%"}
                            marginBottom={5}
                            className="subject_field"
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    paddingX: 8.5,
                                    paddingY: 2,
                                    borderRadius: "45px",
                                    fontSize: "28px",
                                }}
                            >
                                {sendButtonState ? (
                                    <div className="disabled-button-block">
                                        {"Loading"}
                                        <CircularProgress
                                            color="inherit"
                                            sx={{
                                                display: "inline-block",
                                                fontSize: "0.75rem",
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <p className="send">{PageGeneralTexts.SEND}</p>
                                )}
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <div className="contactUs-container">
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <MessageSend toggle={handleClose} />
                    </Box>
                </Modal>
            </div>
        </>
    );
};
