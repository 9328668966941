import { PageGeneralTexts, ToasterMessagesEnums } from "commons/enums";
import { TransactionModel } from "Models";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { TransactionsService } from "services/api/TransactionsService.service";
import "./TransactionsHistory.scss";
import {
    Button,
    CardMedia,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

export const TransactionHistory = ({
    transactions,
}: {
    transactions: TransactionModel[];
}) => {
    const [csvDataState, setCSVDataState] = useState("");
    const [fileNameState, setFileNameState] = useState(
        PageGeneralTexts.TRANSACTIONS
    );

    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);

    const getTransactionsExport = async () => {
        try {
            const result: string =
                await TransactionsService.getInstance().getExportData();
            setCSVDataState(result);
            toast.success(ToasterMessagesEnums.CSV_DOWNLOAD);
        } catch (error) {
            console.error(error);
            toast.error(ToasterMessagesEnums.FAILED_CSV_DOWNLOAD);
        }
    };

    useEffect(() => {
        if (csvDataState) {
            csvLinkRef?.current?.link.click();
        }
    }, [csvDataState]);

    const convertToDate = (dateTimestamp: number) => {
        const date = new Date(dateTimestamp);

        return `${date.getDate()}.${date.getMonth() + 1}.${date
            .getFullYear()
            .toString()
            .slice(2, 4)}`;
    };

    return (
        <>
            <Grid container>
                <Typography
                    variant="body1"
                    fontSize={"64px"}
                    marginX={"auto"}
                    marginTop={0}
                    marginBottom={5}
                    color={"#2457A0"}
                    textAlign={"left"}
                    width={"90%"}
                    fontWeight={700}
                    className="history"
                >
                    {PageGeneralTexts.PAYMENT_HISTORY}
                </Typography>
                <TableContainer className="table_grid" component={Paper}>
                    <Table
                        sx={{
                            maxWidth: "90%",
                            margin: "auto",
                            paddingX: "15px",
                        }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="th_cell"
                                    align="center"
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        color: "#2457A0",
                                        paddingY: "30px",
                                    }}
                                >
                                    {PageGeneralTexts.DATE}
                                </TableCell>
                                <TableCell
                                    className="th_cell"
                                    align="center"
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        color: "#2457A0",
                                        paddingY: "30px",
                                    }}
                                >
                                    {PageGeneralTexts.SUBTOTAL}
                                </TableCell>
                                <TableCell
                                    className="th_cell"
                                    align="center"
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        color: "#2457A0",
                                        paddingY: "30px",
                                    }}
                                >
                                    {PageGeneralTexts.TRANSACTIONS_TYPE}
                                </TableCell>
                                {/* <TableCell
                                    className="th_cell"
                                    align="center"
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        color: "#2457A0",
                                        paddingY: "30px",
                                    }}
                                >
                                    {PageGeneralTexts.STATUS}
                                </TableCell> */}
                                <TableCell
                                    className="th_cell"
                                    align="center"
                                    sx={{
                                        fontSize: "32px",
                                        fontWeight: "700",
                                        color: "#2457A0",
                                        paddingY: "30px",
                                    }}
                                >
                                    {PageGeneralTexts.TRAN_ID}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {transactions.map((t, key) => (
                                <TableRow
                                    key={key}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        className="td_cell"
                                        align="center"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "400",
                                            color: "#2457A0",
                                        }}
                                    >
                                        {convertToDate(t.createdTimestamp)}
                                    </TableCell>
                                    <TableCell
                                        className="td_cell"
                                        align="center"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "400",
                                            color: "#2457A0",
                                        }}
                                    >
                                        {t.amount
                                            .toFixed(0)
                                            .replace(
                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                "$1,"
                                            )}
                                    </TableCell>
                                    <TableCell
                                        className="td_cell"
                                        align="center"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "400",
                                            color: "#2457A0",
                                        }}
                                    >
                                        {t.type}
                                    </TableCell>
                                    {/* <TableCell
                                        className="td_cell"
                                        align="center"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "400",
                                            color: "#2457A0",
                                        }}
                                    >
                                        {t.status}
                                    </TableCell> */}
                                    <TableCell
                                        className="td_cell"
                                        align="center"
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "400",
                                            color: "#2457A0",
                                        }}
                                    >
                                        {t.tranId}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <Button
                            type="submit"
                            variant="contained"
                            className="export"
                            onClick={getTransactionsExport}
                            sx={{
                                backgroundColor: "#EEF9F7",
                                paddingX: "0",
                                paddingY: "0",
                                border: "0px",
                                marginTop: "0",
                                marginBottom: "0",
                                background: "transparent",
                                boxShadow: "none",
                                position: "absolute",
                                right: "0px",
                                bottom: "15px",
                                ":hover": {
                                    backgroundColor: "transparent !important",
                                    boxShadow:"none !important"
                                },
                            }}
                        >
                            <CardMedia
                                sx={{ backgroundColor: "transparent", width:"25px !important" }}
                                component="img"
                                image="images/export.svg"
                                alt="banner"
                            />
                        </Button>
                        <CSVLink
                            ref={csvLinkRef}
                            className="export-btn"
                            asyncOnClick={true}
                            data={csvDataState}
                            filename={
                                fileNameState + Date.now().toString() + ".csv"
                            }
                            target="_blank"
                            hidden={true}
                        />
                    </Table>
                </TableContainer>
            </Grid>
        </>
    );
};
