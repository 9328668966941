import axios from "axios";
import { RequestEnum, Routes, StorageKeys } from "commons/enums";
import { ErrorResponse } from "commons/interfaces";
import { TransactionsResponseType } from "commons/types";
import { environment } from "environments/Environment";
import { AuthService, LocalStorageService } from "services";
import GlobalApiService from "./GlobalApi.service";

export class TransactionsService {
    private static instance: TransactionsService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new TransactionsService();
        }
        return this.instance;
    }

    async getResearcherTransactions(): Promise<
        TransactionsResponseType | string
    > {
        const transactions: TransactionsResponseType | ErrorResponse | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                `${Routes.GET_RESEARCHER_TRANSACTIONS}`,
                []
            );
        let res = {} as TransactionsResponseType | string;

        if (
            typeof transactions === "object" &&
            "transactions" in transactions
        ) {
            res = transactions;
        }
        if (typeof transactions === "string") {
            res = transactions;
        }
        return res;
    }

    async getExportData(): Promise<string> {
        const response = await axios.request<string>({
            method: RequestEnum.Get,
            url: `${environment.API_URL}${Routes.GET_TRANSACTIONS_EXPORT}`,
            headers: AuthService.getInstance().getByHeaderToken(
                StorageKeys.AccessToken
            ),
        });
        return response.data;
    }
}
