import {
    Box,
    Grid,
    Typography,
    CardMedia,
    TextField,
    Select,
    MenuItem,
    Checkbox,
    Button,
    Modal,
} from "@mui/material";
import {
    HandleText,
    PageGeneralTexts,
    PhonePrefixResponse,
    ResponsesKeysEnums,
    ToasterMessagesEnums,
} from "commons/enums";
import { PhonePrefix, PrefixesResponseType, SiteSettings } from "commons/types";
import { Researcher } from "Models";
import React, { useEffect, useReducer, useState } from "react";
import { ResearcherService } from "services/api/ResearcherService.service";
import FormReducer from "./FormReducer/FormReducer";
import "./Register.scss";
import { LocalStorageService } from "services";
import { ModalStyle } from "commons/GlobalModalStyle";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Confirmation } from "./confirmation/Confirmation";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import parse from 'html-react-parser';

export const Register = () => {
    const [prefix, setPrefix] = useState([] as PhonePrefix[]);
    const termsAndConditions = {
        inputProps: { "aria-label": "Checkbox termsAndConditions" },
    };
    const theData = {
        inputProps: { "aria-label": "Checkbox theData" },
    };
    const [organizationError, setOrganizationError] = React.useState(false);
    const [businessIdError, setBusinessIdError] = React.useState(false);
    const [selectPrefix, setSelectPrefix] = useState("+972");
    const [registerForm, dispatchRegisterForm] = useReducer(
        FormReducer,
        {} as Researcher
    );
    const [phone, setPhone] = useState("");
    const [open, setOpen] = useState(false);
    const [submissionError, setSubmissionError] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [toggleAreaOptions, setToggleAreaOptions] = useState(false);
    const style = ModalStyle;
    const [settings, setSettings] = useState<SiteSettings>({} as SiteSettings);

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: "10%",
            },
        },
    };

    const registerSchema = yup.object().shape({
        firstName: yup.string().required().min(2),
        lastName: yup.string().required().min(2),
        personalId: yup.string().required().min(2),
        requestContent: yup.string().required().min(2),
        phone: yup.string().required().trim().min(2),
        prefix: yup.string().required(),
        email: yup.string().email().required().trim().min(2),
        organization: yup.string(),
        businessId: yup.string(),
        theData: yup.boolean().required().isTrue(),
        termsAndConditions: yup.boolean().required().isTrue(),
    });

    const getSiteSettings = () => {
        const local = new LocalStorageService();
        const settingsString: string =
            local.getItemFromLocalStorage("smd") || "";
        if (settingsString !== "") {
            const siteSettings: SiteSettings = JSON.parse(settingsString);
            setSettings(siteSettings);
        }
    };

    const handleTextChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const name = e.target.name;
        const value = e.target.value;
        dispatchRegisterForm({
            type: HandleText.Text,
            key: name,
            value: value,
        });
    };

    const getPrefixes = async () => {
        const result: PrefixesResponseType | string =
            await ResearcherService.getInstance().getPrefixes();
        if (
            typeof result === "object" &&
            PhonePrefixResponse.RESPONSE in result
        ) {
            setPrefix(result.prefix);
        }
    };

    const handlePrefix = (prefix: string) => {
        console.log(prefix);

        setSelectPrefix(prefix);
    };

    const addPotentialResearcher = async (formValue: any) => {
        setOrganizationError(false);
        setBusinessIdError(false);
        const form = formValue;
        form.phone = selectPrefix + phone;
        const researcher: Researcher = { ...form };
        if (!form.businessId && !form.organization) {
            // If a user enters these fields and then deletes the values
            // then they should be deleted from the form sent to the server so we would not get an 400 error
            // because when they are sent as empty string they cause an error
            delete researcher.businessId;
            delete researcher.organization;
        }
        if (!form.businessId && form.organization) {
            setBusinessIdError(true);
            return;
        }
        if (!form.organization && form.businessId) {
            setOrganizationError(true);
            return;
        }
        const result =
            await ResearcherService.getInstance().addNewPotentialResearcher(
                researcher
            );

        if (
            typeof result === "object" &&
            ResponsesKeysEnums.NEW_RESEARCHER_RESPONSE in result
        ) {
            toast.success(ToasterMessagesEnums.REGISTER_SUCCESSFUL);
            handleOpen();
        } else {
            setSubmissionError(result);
            toast.error(result);
        }
    };

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(registerSchema) });

    const errorsArr = Object.entries(errors).map(([key, val]) => ({key, ...val}));

    const onSubmit = handleSubmit(async (formValue) => {
        if (Object.keys(errors).length === 0) {
            await addPotentialResearcher(formValue);
        }
    });

    useEffect(() => {
        getPrefixes();
        getSiteSettings();
    }, []);

    return (
        <form onSubmit={onSubmit}>
            <Grid
                container
                xs={12}
                sx={{ backgroundColor: "#F9FBFC" }}
                className="register-page"
            >
                <Grid item xs={12}>
                    <Box sx={{ backgroundColor: "#F9FBFC" }}>
                        <img
                            style={{ width: "100%", backgroundSize: "cover" }}
                            src="images/home-top-banner.svg"
                            alt="banner"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: "#F9FBFC" }}>
                    <Typography
                        variant="body1"
                        fontSize={"24px"}
                        marginX={15}
                        marginTop={10}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"60%"}
                        fontWeight={400}
                        className="register_instructions p-margin-remove"
                    ><br/>
                        {parse(settings.register_instructions || '<span></span>')}
                    </Typography>
                    <CardMedia
                        component="img"
                        height="auto"
                        image="images/registerVector.svg"
                        alt="banner"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    margin={"auto"}
                    sx={{ backgroundColor: "#F9FBFC" }}
                >
                    <Typography
                        variant="h1"
                        fontSize={"70px"}
                        marginLeft={"7%"}
                        marginTop={10}
                        marginBottom={5}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"93%"}
                        fontWeight={700}
                        className="registration_title"
                    >
                        {PageGeneralTexts.REGISTRATION_TITLE}
                    </Typography>
                </Grid>

                <Grid
                    container
                    xs={10}
                    className="form_container"
                    sx={{ backgroundColor: "#F9FBFC" }}
                >
                    <Grid
                        container
                        xs={12}
                        marginBottom={10}
                        display={"flex"}
                        direction={"row"}
                    >
                        <Grid
                            container
                            display={"flex"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            xs={12}
                            md={10}
                            margin={"auto"}
                        >
                            <Grid
                                container
                                xs={12}
                                md={3.9}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        marginX={1}
                                        color={"#00AB9F"}
                                        textAlign={"left"}
                                        fontWeight={700}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.GENERAL_STAR}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.FIRST_NAME}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={errors.firstName ? "error" : ""}
                                    minLength={2}
                                    id="firstName"
                                    placeholder="First Name"
                                    {...register("firstName")}
                                />
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                md={3.9}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        marginX={1}
                                        color={"#00AB9F"}
                                        textAlign={"left"}
                                        fontWeight={700}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.GENERAL_STAR}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.LAST_NAME}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={errors.lastName ? "error" : ""}
                                    minLength={2}
                                    placeholder="Last Name"
                                    id="lastName"
                                    {...register("lastName")}
                                />
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                md={3.9}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        marginX={1}
                                        color={"#00AB9F"}
                                        textAlign={"left"}
                                        fontWeight={700}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.GENERAL_STAR}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                        whiteSpace="nowrap"
                                    >
                                        {PageGeneralTexts.ID_PASSPORT}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={errors.personalId ? "error" : ""}
                                    minLength={2}
                                    placeholder="ID"
                                    id="personalId"
                                    {...register("personalId")}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            display={"flex"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            xs={12}
                            md={10}
                            margin={"auto"}
                        >
                            <Grid
                                container
                                xs={12}
                                md={3.9}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        marginLeft={1}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.BUSINESS_ID}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={businessIdError ? "error" : ""}
                                    minLength={2}
                                    id="businessId"
                                    placeholder="Private Company Number"
                                    {...register("businessId")}
                                />
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                md={7.95}
                                display={"flex"}
                                direction={"row"}
                                justifyContent={"space-between"}
                                paddingBottom={5}
                                className="phone_prefix"
                            >
                                <Grid
                                    container
                                    xs={5}
                                    display={"flex"}
                                    direction={"column"}
                                    className="prefix"
                                >
                                    <Grid
                                        item
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            fontSize={"24px"}
                                            margin={0}
                                            marginX={1}
                                            color={"#00AB9F"}
                                            textAlign={"left"}
                                            fontWeight={700}
                                            className="input_label"
                                        >
                                            {PageGeneralTexts.GENERAL_STAR}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            fontSize={"24px"}
                                            margin={0}
                                            color={"#666"}
                                            textAlign={"left"}
                                            mb={1}
                                            fontWeight={500}
                                            className="input_label"
                                        >
                                            {PageGeneralTexts.COUNTY_CODE}
                                        </Typography>
                                    </Grid>
                                    <Select
                                        value={selectPrefix}
                                        sx={{
                                            width: "100%",
                                            height: "60px",
                                            backgroundColor: "#f1f9fe",
                                            border: "2px solid #666",
                                        }}
                                        {...register("prefix")}
                                        id="prefix"
                                        className={errors.prefix ? "error" : ""}
                                        MenuProps={MenuProps}
                                        displayEmpty
                                    >
                                        <MenuItem value="">
                                            <Typography
                                                variant="body1"
                                                fontSize={"18px"}
                                                margin={0}
                                                color={"rgba(17, 17, 19, 0.40)"}
                                                fontWeight={500}
                                                textAlign={"left"}
                                            >
                                                {"Area"}
                                            </Typography>
                                        </MenuItem>
                                        {prefix.map((fix, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={fix.dial_code}
                                                    className="options"
                                                    onClick={() => {
                                                        handlePrefix(
                                                            fix.dial_code
                                                        );
                                                        setToggleAreaOptions(
                                                            !toggleAreaOptions
                                                        );
                                                    }}
                                                >
                                                    {fix.name +
                                                        " " +
                                                        fix.dial_code}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                                <Grid
                                    container
                                    xs={6.8}
                                    display={"flex"}
                                    alignItems={"end"}
                                    className="phone"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            fontSize={"24px"}
                                            margin={0}
                                            marginX={1}
                                            color={"#00AB9F"}
                                            textAlign={"left"}
                                            fontWeight={700}
                                            className="input_label"
                                        >
                                            {PageGeneralTexts.GENERAL_STAR}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            fontSize={"24px"}
                                            margin={0}
                                            color={"#666"}
                                            textAlign={"left"}
                                            mb={1}
                                            fontWeight={500}
                                            className="input_label"
                                        >
                                            {PageGeneralTexts.PHONE_NUMBER}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            className={
                                                errors.phone ? "error" : ""
                                            }
                                            minLength={2}
                                            placeholder="Phone Number For Contact"
                                            id="phone"
                                            {...register("phone")}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                handleTextChange(e);
                                                setPhone(e.target.value);
                                            }}
                                            sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            display={"flex"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            xs={12}
                            md={10}
                            margin={"auto"}
                        >
                            <Grid
                                container
                                xs={12}
                                md={6.95}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        marginX={1}
                                        color={"#00AB9F"}
                                        textAlign={"left"}
                                        fontWeight={700}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.GENERAL_STAR}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.EMAIL}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={errors.email ? "error" : ""}
                                    minLength={2}
                                    id="email"
                                    placeholder="Email"
                                    {...register("email")}
                                />
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                md={4.9}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        marginLeft={1}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.ORGANIZATION}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={organizationError ? "error" : ""}
                                    minLength={2}
                                    placeholder="Company/Institution"
                                    id="organization"
                                    {...register("organization")}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            display={"flex"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            xs={12}
                            md={10}
                            margin={"auto"}
                        >
                            <Grid
                                container
                                xs={12}
                                md={12}
                                display={"flex"}
                                direction={"column"}
                                paddingBottom={5}
                            >
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        marginX={1}
                                        color={"#00AB9F"}
                                        textAlign={"left"}
                                        fontWeight={700}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.GENERAL_STAR}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#666"}
                                        textAlign={"left"}
                                        mb={1}
                                        fontWeight={500}
                                        className="input_label"
                                    >
                                        {PageGeneralTexts.REGISTER_REQUEST}
                                    </Typography>
                                </Grid>

                                <TextField
                                    variant="outlined"
                                    className={
                                        errors.requestContent ? "error" : ""
                                    }
                                    multiline
                                    rows={3}
                                    id="requestContent"
                                    placeholder={"Write the purpose of the data access request"}
                                    {...register("requestContent")}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            md={10}
                            display={"flex"}
                            direction={"row"}
                            margin={"auto"}
                            mb={2}

                        >
                            <Checkbox
                                sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: 1,
                                    "&.Mui-checked": {
                                        color: "#666666",
                                    },
                                }}
                                id="theData"
                                className={
                                    errors.theData ? "error" : ""
                                }
                                {...theData}
                                {...register("theData")}
                            />
                            <Typography
                                variant="body1"
                                fontSize={"17px"}
                                width={"70%"}
                                margin={0}
                                color={"#666"}
                                textAlign={"left"}
                                fontWeight={500}
                                className={
                                    errors.theData
                                        ? "text-error terms"
                                        : "terms"
                                }
                            >
                                {PageGeneralTexts.TERMS_CONDITIONS_ONE}
                                <Link
                                    to="/theData"
                                    target="_blank"
                                    className="terms-link"
                                >
                                    <u>{PageGeneralTexts.THE_DATA}</u>
                                </Link>
                                {PageGeneralTexts.TERMS_CONDITIONS_THREE}
                            </Typography>
                        </Grid>
                        <Grid
                            container
                            xs={12}
                            md={10}
                            display={"flex"}
                            direction={"row"}
                            margin={"auto"}
                        >
                            <Checkbox
                                sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: 1,
                                    "&.Mui-checked": {
                                        color: "#666666",
                                    },
                                }}
                                id="termsAndConditions"
                                className={
                                    errors.termsAndConditions ? "error" : ""
                                }
                                {...termsAndConditions}
                                {...register("termsAndConditions")}
                            />
                            <Typography
                                variant="body1"
                                fontSize={"17px"}
                                width={"70%"}
                                margin={0}
                                color={"#666"}
                                textAlign={"left"}
                                fontWeight={500}
                                className={
                                    errors.termsAndConditions
                                        ? "text-error terms"
                                        : "terms"
                                }
                            >
                                {PageGeneralTexts.TERMS_CONDITIONS_FOUR}
                                <Link
                                    to="/terms"
                                    target="_blank"
                                    className="terms-link"
                                >
                                    <u>{PageGeneralTexts.TERMS_CONDITIONS_TWO}</u>
                                </Link>
                                {PageGeneralTexts.TERMS_CONDITIONS_FIVE}
                                <Link
                                    to="/טיוטת הסכם- שימוש במאגר נתונים.pdf"
                                    target="_blank"
                                    className="terms-link"
                                >
                                    <u>{PageGeneralTexts.TERMS_CONDITIONS_TWO}</u>
                                </Link>
                                {PageGeneralTexts.TERMS_CONDITIONS_SIX}
                            </Typography>
                        </Grid>

                        {organizationError || businessIdError ||
                        Object.keys(errors).length ? (
                            <>
                            
                            {(organizationError || businessIdError || errorsArr.some(err => err.type === 'required')) && (
                                <Grid
                                    container
                                    xs={12}
                                    md={10}
                                    display={"flex"}
                                    justifyContent={"start"}
                                    margin={"auto"}
                                >
                                    <div
                                        className="alert-error-submit"
                                        role="alert"
                                    >
                                        <InfoIcon sx={{ m: -0.5 }} /> Please fill the missing fields
                                    </div>
                                </Grid>
                            )}

                            {errorsArr.some(err => err.type === 'min') && (
                                <Grid
                                    container
                                    xs={12}
                                    md={10}
                                    display={"flex"}
                                    justifyContent={"start"}
                                    margin={"auto"}
                                    marginTop={2}
                                >
                                    <div
                                        className="alert-error-submit"
                                        role="alert"
                                    >
                                        <InfoIcon sx={{ m: -0.5 }} /> {' '}
                                        {errorsArr.find(err => err.key === 'personalId') ? 'Invalid ID Number' : 'Field value is too short'}
                                    </div>
                                </Grid>
                            )}

                            </>
                        ) : null}

                        {submissionError.length > 0 ? (
                            <Grid
                                container
                                xs={12}
                                md={10}
                                margin={"30px auto auto auto"}
                                position={"relative"}
                            >
                                <Typography
                                    variant="body1"
                                    fontSize={"22px"}
                                    width={"100%"}
                                    margin={0}
                                    color={"crimson"}
                                    textAlign={"left"}
                                    fontWeight={500}
                                >
                                    <InfoIcon sx={{ m: -0.5 }} /> {submissionError}
                                </Typography>
                            </Grid>
                        ) : (
                            ""
                        )}

                        <Grid
                            container
                            xs={12}
                            md={10}
                            display={"flex"}
                            justifyContent={"start"}
                            margin={"auto"}
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#2457A0",
                                    paddingX: "40px",
                                    paddingY: "22px",
                                    fontSize: "22px",
                                    color: "white",
                                    borderRadius: "45px",
                                    marginTop: "80px",
                                    marginBottom: "40px",
                                }}
                                className="submit_registration"
                                onClick={() => {
                                    setSubmissionError('');
                                }}
                            >
                                {PageGeneralTexts.REGISTRATION_TITLE}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Confirmation toggle={handleClose} />
                    </Box>
                </Modal>
            </Grid>
        </form>
    );
};
