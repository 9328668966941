import { HandleText } from "commons/enums/FormReducerActions.enum";
import { ActionReducer } from "commons/types/FromActionReducerType";
import { Researcher } from "Models";

const FormReducer = (state: Researcher, action: ActionReducer) => {
    switch (action.type) {
        case HandleText.Text:
            return {
                ...state,
                [action.key]: action.value,
            };
        case HandleText.Boolean:
            return {
                ...state,
                [action.key]: JSON.parse(action.value),
            }
        default:
            return state;
    }
};

export default FormReducer;
