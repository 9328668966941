/**
 *** valid phone numbers
 +972526399005
 9720526399005
 0526399005
 +10526399005
 5555555555
 1234567890
 +4912312345678901
 *** invalid phone numbers
 a0526399005
 972052639900522223
 +972a0526399005
 (0123) 123 456 1
 004915555323455
 +1(234)5689901
 +46.234.567.8901
 +1/234/567/8901
 */

 export const E164_PHONE_REGEX = /^((\+)?([1-9]{1,3}))?\d{10,14}$/;
