import {
    PhonePrefixResponse,
    RequestEnum,
    ResponsesKeysEnums,
    Routes,
} from "commons/enums";
import { ErrorResponse } from "commons/interfaces";
import {
    DataFileResponseType,
    PrefixesResponseType,
    ProfileResponseType,
    ResearcherResponseType,
    ReviewResponseType,
    ReviewType,
    TerminateResponseType,
} from "commons/types";
import { LocalStorageService } from "services/LocalStorage.service";
import GlobalApiService from "./GlobalApi.service";
import { Researcher } from "Models";

export class ResearcherService {
    private static instance: ResearcherService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new ResearcherService();
        }
        return this.instance;
    }

    async addNewPotentialResearcher(
        researcher: Researcher
    ): Promise<ResearcherResponseType | string> {
        const newResearcher: ResearcherResponseType | ErrorResponse | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Post,
                Routes.POST_REGISTER,
                researcher
            );
        let res = {} as ResearcherResponseType | string;
        
        if (
            typeof newResearcher === "object" &&
            ResponsesKeysEnums.NEW_RESEARCHER_RESPONSE in newResearcher
        ) {
            res = newResearcher;
        }
        if (typeof newResearcher === "string") {
            res = newResearcher;
        }
        return res;
    }

    async getDataFile(id: string): Promise<DataFileResponseType | string> {
        const result: DataFileResponseType | string | ErrorResponse =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                `${Routes.GET_DATA_FILE}/${id}/get-data-file`,
                []
            );
        let res = {} as DataFileResponseType | string;
        if (typeof result === "object" && "statusCode" in result) {
            //error
            res = result.message;
        } else if (typeof result === "object" && "message" in result) {
            res = result;
        } else if (typeof result === "string") {
            res = result;
        }

        return res;
    }

    async endSubscription(id: string): Promise<TerminateResponseType | string> {
        const result: TerminateResponseType | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                `${Routes.GET_TERMINATION}/${id}/end-subscription`,
                []
            );
        let res = {} as TerminateResponseType | string;
        if (typeof result === "object" && "message" in result) {
            res = result;
        }
        if (typeof result === "string") {
            res = result;
        }
        return res;
    }

    async getPrefixes(): Promise<PrefixesResponseType | string> {
        const prefixResponse: PrefixesResponseType | ErrorResponse | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.GET_PREFIX,
                []
            );
        let res = {} as PrefixesResponseType | string;
        if (
            typeof prefixResponse === "object" &&
            PhonePrefixResponse.RESPONSE in prefixResponse
        ) {
            res = prefixResponse;
        }
        if (typeof prefixResponse === "string") {
            res = prefixResponse;
        }
        return res;
    }

    async submitReview(
        id: string,
        review: ReviewType
    ): Promise<ReviewResponseType | string> {
        const response: ReviewResponseType | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Put,
                `${Routes.PUT_RESEARCHER_REVIEW}/${id}/submit-review-form`,
                review
            );
        let res = {} as ReviewResponseType | string;
        if (typeof response === "object" && "message" in response) {
            res = response;
        }
        if (typeof response === "string") {
            res = response;
        }
        return res;
    }

    async getResearcherProfile(
        id: string
    ): Promise<ProfileResponseType | string> {
        const researcher: ProfileResponseType | ErrorResponse | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                `${Routes.GET_RESEARCHER_PROFILE}/${id}/profile`,
                []
            );
        let res = {} as ProfileResponseType | string;
        if (typeof researcher === "object" && "profile" in researcher) {
            res = researcher;
        }
        if (typeof researcher === "string") {
            res = researcher;
        }
        return res;
    }

    async findResearcherByResearcherId(
        id: string
    ): Promise<Researcher | string> {
        const response: Researcher | ErrorResponse | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                `${Routes.GET_RESEARCHER_DETAILS}/${id}`,
                []
            );
        let res = {} as Researcher | string;
        if (typeof response === "object" && "_id" in response) {
            res = response;
        }
        if (typeof response === "string") {
            res = response;
        }
        return res;
    }
}
