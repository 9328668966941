import React, { useEffect, useState } from "react";
import { PageGeneralTexts, ToasterMessagesEnums } from "commons/enums";
import { Dropdown } from "../global/Dropdown/Dropdown";
import "./FAQ.scss";
import { FAQ as FaqType, FaqPageResponseType } from "commons/types";
import { SettingsService } from "services/api/SettingsService.service";
import { toast } from "react-toastify";
import { Box, Grid, Typography } from "@mui/material";

export const FAQ = () => {
    const [faq, setFaq] = useState([] as FaqType[]);

    const getFaqData = async () => {
        const result: FaqPageResponseType | string =
            await SettingsService.getInstance().fetchFaq();
        if (typeof result === "object" && "faq" in result) {
            setFaq(result.faq);
        }
        if (typeof result === "string") {
            toast.error(ToasterMessagesEnums.FAILED_TO_GET_FAQ);
        }
    };

    useEffect(() => {
        getFaqData();
        window.scrollTo(0,0)
    }, []);

    return (
        <Grid
            container
            xs={12}
            marginBottom={20}
            sx={{ backgroundColor: "#F1F9FE" }}
            className="faq-container"
        >
            <Grid item xs={12}>
                <Box>
                    <img
                        style={{ width: "100%", backgroundSize: "cover" }}
                        src="images/main-banner.png"
                        alt="banner"
                    />
                </Box>
            </Grid>
            <Grid
                container
                xs={10}
                display={"flex"}
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"left"}
                sx={{ margin: "auto" }}
            >
                <Grid item>
                    <Typography
                        variant="h1"
                        fontSize={"70px"}
                        margin={0}
                        color={"#2457a0"}
                        textAlign={"left"}
                        fontWeight={700}
                        className="faq-title"
                    >
                        {PageGeneralTexts.FAQ}
                    </Typography>
                </Grid>
                <Grid container>
                    {faq?.map((f: FaqType) => {
                        return (
                            <Grid item xs={12}>
                                <Dropdown
                                    question={f.question}
                                    answer={f.answer}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};
