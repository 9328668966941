import { PageGeneralTexts } from "commons/enums";
import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

export const Footer = () => {
    return (
        <Grid
            container
            className="footer"
            xs={12}
            sx={{ backgroundColor: "#2457A0" }}
        >
            <Grid className="gradient-bar"></Grid>

            <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                paddingTop={7}
                paddingBottom={0}
                className="web"
            >
                <Grid
                    container
                    xs={12}
                    md={4}
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"center"}
                    sx={{ marginBottom: 5 }}
                >
                    <Grid item xs={12} md={8} display='flex' direction='column' alignItems='center'>
                        <Box textAlign={{ xs: "center", md: "left" }}>
                            <Box sx={{ marginBottom: 3, marginTop: 1 }}>
                                <img
                                    style={{
                                        width: "115px",
                                        backgroundSize: "cover",
                                    }}
                                    src="images/footerIcon.png"
                                    alt="banner"
                                />
                            </Box>
                            <Box className="payNresearch">
                                <img
                                    style={{
                                        height: "40px",
                                        width: "190px",
                                        backgroundSize: "cover",
                                    }}
                                    src="images/payresearch.svg"
                                    alt="banner"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={4} sx={{ marginBottom: 5 }}>
                    <Typography
                        variant="body1"
                        fontSize={"16px"}
                        margin={"auto"}
                        color={"white"}
                        textAlign={"left"}
                        width={"90%"}
                        mb={0}
                        fontWeight={300}
                    >
                        {PageGeneralTexts.DESCRIPTION}
                    </Typography>
                    <Link
                        to="https://www.innovation.leumit.co.il/"
                        target="_blank"
                        className="link_to"
                    >
                        <Typography
                            variant="body1"
                            fontSize={"18px"}
                            margin={"auto"}
                            color={"white"}
                            textAlign={"left"}
                            width={"90%"}
                            mb={0}
                            className="link-to-visit"
                        >
                            Visit:
                        </Typography>
                        <Typography
                            variant="body1"
                            fontSize={"18px"}
                            margin={"auto"}
                            color={"white"}
                            textAlign={"left"}
                            width={"90%"}
                            mb={0}
                            className="link-to-homesite"
                        >
                            www.innovation.leumit.co.il
                        </Typography>
                    </Link>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        className="link_to"
                    >
                        <Grid item sx={{ mt: 2 }}>
                            <a href="https://cambium.co.il" style={{ display: 'flex', alignItems: 'center' }}>
                                <span className="developedBy">Developed by</span>
                                <img
                                    src="images/cambium.svg"
                                    alt="cambium"
                                    className="cambium"
                                    style={{ marginLeft: '8px' }} // Optional: Add spacing between text and image
                                />
                            </a>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid
                    container
                    xs={12}
                    md={4}
                    display={"flex"}
                    direction={"column"}
                    className="links"
                    sx={{ marginBottom: 5 }}
                >
                    <Grid sx={{ width: "fit-content", margin: "0px auto" }}>
                        <Grid item>
                            <Link to="/">
                                <Typography
                                    variant="body1"
                                    fontSize={"16px"}
                                    margin={0}
                                    color={"#8EDAE6"}
                                    textAlign={"left"}
                                    mb={0}
                                    fontWeight={700}
                                >
                                    {PageGeneralTexts.HOME_LINK}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/terms">
                                <Typography
                                    variant="body1"
                                    fontSize={"16px"}
                                    margin={0}
                                    color={"#8EDAE6"}
                                    textAlign={"left"}
                                    mb={0}
                                    fontWeight={700}
                                >
                                    {PageGeneralTexts.TERMS_LINK}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/faq">
                                <Typography
                                    variant="body1"
                                    fontSize={"16px"}
                                    margin={0}
                                    color={"#8EDAE6"}
                                    textAlign={"left"}
                                    mb={0}
                                    fontWeight={700}
                                >
                                    {PageGeneralTexts.FAQ_LINK}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/theData">
                                <Typography
                                    variant="body1"
                                    fontSize={"16px"}
                                    margin={0}
                                    color={"#8EDAE6"}
                                    textAlign={"left"}
                                    mb={0}
                                    fontWeight={700}
                                >
                                    {PageGeneralTexts.DISCLAIMER_LINK}
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/contactUs">
                                <Typography
                                    variant="body1"
                                    fontSize={"16px"}
                                    margin={0}
                                    color={"#8EDAE6"}
                                    textAlign={"left"}
                                    mb={0}
                                    fontWeight={700}
                                >
                                    {PageGeneralTexts.CONTACT_LINK}
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                paddingTop={7}
                paddingBottom={0}
                className="mobile"
            >
                <Grid
                    container
                    xs={12}
                    md={4}
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"start"}
                    sx={{ marginBottom: 5 }}
                >
                    <Grid item xs={12} md={4} sx={{ marginBottom: 5 }}>
                        <Typography
                            variant="body1"
                            fontSize={"16px"}
                            margin={"auto"}
                            color={"white"}
                            textAlign={"left"}
                            width={"90%"}
                            mb={0}
                            fontWeight={300}
                        >
                            {PageGeneralTexts.DESCRIPTION}
                        </Typography>
                        <Link
                            to="https://www.innovation.leumit.co.il/"
                            target="_blank"
                            className="link_to"
                        >
                            <Typography
                                variant="body1"
                                fontSize={"18px"}
                                margin={"auto"}
                                color={"white"}
                                textAlign={"left"}
                                width={"90%"}
                                mb={0}
                                className="link-to-visit"
                            >
                                Visit:
                            </Typography>
                            <Typography
                                variant="body1"
                                fontSize={"18px"}
                                margin={"auto"}
                                color={"white"}
                                textAlign={"left"}
                                width={"90%"}
                                mb={0}
                                className="link-to-homesite"
                            >
                                 www.innovation.leumit.co.il
                            </Typography>
                        </Link>
                    </Grid>

                    <Grid container display={"flex"} direction={"row"}>
                        <Grid
                            item
                            xs={6}
                            paddingLeft={3}
                            marginTop={"10px auto"}
                            paddingBottom={"15px"}
                        >
                            <Box display={"flex"} width={"90%"} margin={"auto"}>
                                <img
                                    style={{
                                        width: "90px",
                                        height: "auto",
                                        backgroundSize: "cover",
                                    }}
                                    src="images/footerIcon.png"
                                    alt="banner"
                                />
                            </Box>
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={"auto"}
                                color={"white"}
                                textAlign={"left"}
                                width={"90%"}
                                mb={0}
                                mt={1}
                                fontWeight={700}
                                letterSpacing={1.5}
                            >
                                {PageGeneralTexts.PAY_RESEARCH}
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            paddingLeft={5}
                            sx={{ width: "fit-content", margin: "auto" }}
                        >
                            <Grid item>
                                <Link to="/">
                                    <Typography
                                        variant="body1"
                                        fontSize={"16px"}
                                        margin={0}
                                        color={"#8EDAE6"}
                                        textAlign={"left"}
                                        mb={0}
                                        fontWeight={700}
                                    >
                                        {PageGeneralTexts.HOME_LINK}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/terms">
                                    <Typography
                                        variant="body1"
                                        fontSize={"16px"}
                                        margin={0}
                                        color={"#8EDAE6"}
                                        textAlign={"left"}
                                        mb={0}
                                        fontWeight={700}
                                    >
                                        {PageGeneralTexts.TERMS_LINK}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/faq">
                                    <Typography
                                        variant="body1"
                                        fontSize={"16px"}
                                        margin={0}
                                        color={"#8EDAE6"}
                                        textAlign={"left"}
                                        mb={0}
                                        fontWeight={700}
                                    >
                                        {PageGeneralTexts.FAQ_LINK}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/theData">
                                    <Typography
                                        variant="body1"
                                        fontSize={"16px"}
                                        margin={0}
                                        color={"#8EDAE6"}
                                        textAlign={"left"}
                                        mb={0}
                                        fontWeight={700}
                                    >
                                        {PageGeneralTexts.DISCLAIMER_LINK}
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to="/contactUs">
                                    <Typography
                                        variant="body1"
                                        fontSize={"16px"}
                                        margin={0}
                                        color={"#8EDAE6"}
                                        textAlign={"left"}
                                        mb={0}
                                        fontWeight={700}
                                    >
                                        {PageGeneralTexts.CONTACT_LINK}
                                    </Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
