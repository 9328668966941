export enum Routes {
    // ################### LOGIN
    GET_VALIDATE_PHONE = "api/auth/validate-phone",
    GET_VALIDATE_PHONE_BEFORE_OTP = "api/auth/phone",
    POST_LOGOUT = "api/auth/logout",
    POST_OTP = "api/auth/otp",
    GET_RESEND_OTP = "api/auth/resend-otp",
    POST_REGISTER = "api/auth/register-researcher",
    POST_REFRESH_RT = "api/auth/refresh",

    // ##################### REGISTER
    GET_PREFIX = "api/phone-prefix",

    // ##################### RESEARCHER
    GET_RESEARCHER_PROFILE = "api/researchers",
    PUT_RESEARCHER_REVIEW = "api/researchers",
    GET_DATA_FILE = "api/researchers",
    GET_TERMINATION = "api/researchers",
    GET_RESEARCHER_DETAILS = "api/researchers",

    // ##################### TRANSACTIONS
    GET_RESEARCHER_TRANSACTIONS = "api/transactions/by-researcher-id",
    GET_TRANSACTIONS_EXPORT = "api/transactions/export-csv/researcher-transactions",

    // ##################### VIRTUAL MACHINES
    GET_VM_EXPORT = "api/virtual-machines/export-csv/researcher-virtual-machine",

    //  #################### CONTACT US
    POST_NEW_MESSAGE = "api/contact-us",

    // ##################### PAYMENT
    GET_PAYMENT_PAGE = "api/payment/page",
    POST_CHARGE_REQUEST = "api/payment/charge",

    // ##################### SETTINGS
    GET_SETTINGS = "api/settings",
    GET_FAQ = "api/settings/faq",
    GET_PAYMENT_SETTINGS = "api/settings/get-payment-prices"
}
