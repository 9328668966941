import {
    ToasterMessagesEnums,
    PageGeneralTexts,
    PaymentEnums,
} from "commons/enums";
import { Loader } from "components/Loader/Loader";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Payment.scss";
import { PaymentService } from "services/api/PaymentService.service";
import {
    Box,
    Checkbox,
    Container,
    Grid,
    Card,
    Typography,
    Button,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AuthGuardService } from "services/guard";
import { SettingsService, ResearcherService, AuthService } from "services";
import {
    PaymentSettingsResponseType,
    ResearcherDetailsType,
} from "commons/types";
import { Link } from "react-router-dom";
import { E164_PHONE_REGEX } from "commons/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    ChargeResearcherRequest,
    CreditGuardIframeResponseData,
} from "commons/interfaces";

export const Payment = () => {
    const termsAndConditions = {
        inputProps: { "aria-label": "Checkbox termsAndConditions" },
    };
    // ####### SERVICES
    const authGuardServiceInstance: AuthGuardService =
        AuthGuardService.getInstance();
    const researcherPayload =
        authGuardServiceInstance.getCurrentResearcherPayload();
    const settingServiceInstance: SettingsService =
        SettingsService.getInstance();
    const researcherServiceInstance: ResearcherService =
        ResearcherService.getInstance();
    // ######## STATES
    const [paymentURI, setPaymentURI] = useState<string>("");
    const [termsChecked, setTermsChecked] = useState<boolean>(false);
    const [payments, setPayments] = useState<PaymentSettingsResponseType>({
        initial_payment: 0,
        recurring_payment: 0,
        total: 0,
    });
    const [loaderState, setLoaderState] = useState(false);
    const [researcherDetailsState, setResearcherDetailsState] =
        useState<ResearcherDetailsType>({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
        });
    // ######## OTHER
    const navigate = useNavigate();

    const researcherDetailsSchema = yup.object().shape({
        firstName: yup.string().min(2).max(30).required(),
        lastName: yup.string().min(2).max(30).required(),
        phone: yup.string().matches(E164_PHONE_REGEX).required(),
        email: yup.string().email().required(),
    });

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors },
    } = useForm({ resolver: yupResolver(researcherDetailsSchema) });

    const onSubmit = (data: ResearcherDetailsType) => {
        console.log(data);

        toast.success(ToasterMessagesEnums.DETAILS_SAVED);
    };

    const handleTermsChange = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        const isFormValid = await trigger();

        // checking if the form has valid values and no errors
        if (isFormValid) {
            setTermsChecked((prevState) => !prevState);
        } else {
            toast.error("Please make sure each field is correctly filled");
        }
    };

    const setResearcherDetails = async () => {
        const researcherDetails =
            await researcherServiceInstance.findResearcherByResearcherId(
                researcherPayload.researcherId
            );
        if (typeof researcherDetails === "string") {
            toast.error(
                ToasterMessagesEnums.FAILED_TO_FETCH_RESEARCHER_DETAILS
            );
            return;
        }
        setResearcherDetailsState(researcherDetails);
        Object.keys(researcherDetailsState).forEach((fieldName) => {
            const name = fieldName as keyof ResearcherDetailsType;
            setValue(name, researcherDetails[name]);
        });
    };

    const getVirtualLabRoomPaymentDetails = async () => {
        const result: string | PaymentSettingsResponseType =
            await settingServiceInstance.getPaymentSettings();
        if (typeof result === "string") {
            toast.error(ToasterMessagesEnums.FAILED_TO_GET_PAYMENT_SETTINGS);
        }
        if (typeof result === "object") {
            setPayments({
                initial_payment: result.initial_payment,
                recurring_payment: result.recurring_payment,
                total: result.total,
            });
        }
    };

    const fetchPaymentURI = async () => {
        try {
            const URI = await PaymentService.getInstance().getPaymentPage({
                sum: payments.total,
            });
            setPaymentURI(URI as string);
        } catch (error) {
            console.error(error);
            toast.error(ToasterMessagesEnums.FETCHING_PAYMENT_URI_FAILED);
        }
    };

    const handleIframeLoad = async (event: SyntheticEvent) => {
        if (event) {
            let currentSrc;
            try {
                currentSrc = (event.target as HTMLIFrameElement)?.contentWindow?.location.href;
            }
            catch (err) {
                console.error(err);
            }
            // if we get redirected to the success or error callback
            // then this means the current source of the iframe has changed
            // from the initial one in payment URI that we fetched
            if (currentSrc && currentSrc !== paymentURI) {
                setLoaderState(true);
                const [callbackUrl, queryParamsInString] =
                    currentSrc.split("?");

                if (!callbackUrl || !queryParamsInString) {
                    console.error("An error occurred, missing fields");
                    throw new Error("An error occurred, missing fields");
                }
                // getting the parameters from the redirection urls that are returned from the iframe
                const searchParams = new URLSearchParams(queryParamsInString);
                const queryParams: { [key: string]: string } = {};

                for (const [key, value] of searchParams.entries()) {
                    queryParams[key] = value;
                }
                const cgIframeResponse =
                    queryParams as unknown as CreditGuardIframeResponseData;
                if (
                    callbackUrl.includes("/successCallback") &&
                    cgIframeResponse.errorCode === "000"
                ) {
                    await handleIframeSuccessCallback(cgIframeResponse);
                } else if (callbackUrl.includes("/errorCallback")) {
                    toast.error(ToasterMessagesEnums.PAYMENT_IN_MPI_FAILED);
                    handleIframeErrorCallback();
                }
                setLoaderState(false);
            }
        }
    };

    const handleIframeSuccessCallback = async (
        cgIframeResponse: CreditGuardIframeResponseData
    ) => {
        try {
            const researcherChargeRequest: ChargeResearcherRequest = {
                researcher: {
                    _id: researcherPayload.researcherId,
                    email: researcherDetailsState.email,
                    firstName: researcherDetailsState.firstName,
                    lastName: researcherDetailsState.lastName,
                    phone: researcherDetailsState.phone,
                },
                cardExpiration: cgIframeResponse.cardExp,
                cardToken: cgIframeResponse.cardToken,
                txId: cgIframeResponse.txId,
                initialSum: payments.initial_payment,
                recurringSum: payments.recurring_payment,
            };

            const result = await PaymentService.getInstance().chargeResearcher(
                researcherChargeRequest
            );

            await new Promise(r => setTimeout(r, 3000)); // wait 3 seconds for admin-server to change status to APPROVED_PAID

            await AuthService.getInstance().refreshRT(false);
            console.info(result);
            toast.success(ToasterMessagesEnums.CHARGING_PROCESS_SUCCEEDED);
            navigate("/payment-success");
        } catch (error) {
            console.error(error);
            toast.error(ToasterMessagesEnums.CHARGING_PROCESS_FAILED);
            setLoaderState(false);
            handleIframeErrorCallback();
        }
    };

    const handleIframeErrorCallback = () => {
        setTimeout(() => {
            window.location.reload();
        }, 5000);
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                // Perform asynchronous operations using await
                await setResearcherDetails();
                await getVirtualLabRoomPaymentDetails();
            } catch (error) {
                // Handle errors
                console.error("An error occurred:", error);
            }
        };
        fetchInitialData();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (payments.total > 0) {
            fetchPaymentURI();
        }
    }, [payments]);

    return (
        <>
            <Container maxWidth={false} disableGutters>
                <div className="top-banner">
                    <img src="images/home-top-banner.svg" alt="banner" />
                </div>
                <Grid xs={12} paddingBottom={1}>
                    <Grid
                        container
                        item
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        xs={11}
                        md={10}
                        margin={"auto"}
                        marginBottom={0}
                        className="checkout-form"
                    >
                        <Grid
                            container
                            item
                            xs={12}
                            md={6}
                            display={"flex"}
                            direction={"column"}
                        >
                            <h1 className="h1-header">
                                {PageGeneralTexts.CHECKOUT}
                            </h1>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid
                                    container
                                    item
                                    spacing={2}
                                    xs={12}
                                    md={12}
                                >
                                    <Grid item xs={6} md={6}>
                                        <div>
                                            <h5 className="h5-header">
                                                <Typography
                                                    variant="body1"
                                                    fontSize={"24px"}
                                                    margin={0}
                                                    marginX={1}
                                                    color={"#00AB9F"}
                                                    textAlign={"left"}
                                                    fontWeight={700}
                                                    className="input_label"
                                                >
                                                    {
                                                        PageGeneralTexts.GENERAL_STAR
                                                    }
                                                </Typography>
                                                {PageGeneralTexts.FIRST_NAME}
                                            </h5>

                                            <OutlinedInput
                                                sx={{
                                                    background: "#e6f2f7",
                                                    height: "52px",
                                                    padding: "10px 15px",
                                                    fontSize: "24px",
                                                    width: "100%",
                                                }}
                                                type={PaymentEnums.TEXT}
                                                placeholder={
                                                    PaymentEnums.FIRST_NAME
                                                }
                                                {...register(
                                                    PaymentEnums.FIRST_NAME_ID
                                                )}
                                                className={
                                                    errors?.firstName
                                                        ? "input-error outlinedInput"
                                                        : " outlinedInput"
                                                }
                                                id={PaymentEnums.FIRST_NAME_ID}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <div>
                                            <h5 className="h5-header">
                                                <Typography
                                                    variant="body1"
                                                    fontSize={"24px"}
                                                    margin={0}
                                                    marginX={1}
                                                    color={"#00AB9F"}
                                                    textAlign={"left"}
                                                    fontWeight={700}
                                                    className="input_label"
                                                >
                                                    {
                                                        PageGeneralTexts.GENERAL_STAR
                                                    }
                                                </Typography>
                                                {PageGeneralTexts.LAST_NAME}
                                            </h5>

                                            <OutlinedInput
                                                sx={{
                                                    background: "#e6f2f7",
                                                    height: "52px",
                                                    padding: "10px 15px",
                                                    fontSize: "17.3879px",
                                                    width: "100%",
                                                }}
                                                type={PaymentEnums.TEXT}
                                                placeholder={
                                                    PaymentEnums.LAST_NAME
                                                }
                                                {...register(
                                                    PaymentEnums.LAST_NAME_ID
                                                )}
                                                className={
                                                    errors?.lastName
                                                        ? "input-error outlinedInput"
                                                        : " outlinedInput"
                                                }
                                                id={PaymentEnums.LAST_NAME_ID}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <h5 className="h5-header">
                                            <Typography
                                                variant="body1"
                                                fontSize={"24px"}
                                                margin={0}
                                                marginX={1}
                                                color={"#00AB9F"}
                                                textAlign={"left"}
                                                fontWeight={700}
                                                className="input_label"
                                            >
                                                {PageGeneralTexts.GENERAL_STAR}
                                            </Typography>
                                            {PageGeneralTexts.PHONE_NUMBER}
                                        </h5>

                                        <OutlinedInput
                                            sx={{
                                                background: "#e6f2f7",
                                                height: "52px",
                                                padding: "10px 15px",
                                                fontSize: "17.3879px",
                                                width: "100%",
                                            }}
                                            type={PaymentEnums.CELL}
                                            placeholder={PaymentEnums.PHONE}
                                            {...register(PaymentEnums.PHONE_ID)}
                                            className={
                                                errors?.phone
                                                    ? "input-error outlinedInput"
                                                    : " outlinedInput"
                                            }
                                            id={PaymentEnums.PHONE_ID}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <h5 className="h5-header">
                                            <Typography
                                                variant="body1"
                                                fontSize={"24px"}
                                                margin={0}
                                                marginX={1}
                                                color={"#00AB9F"}
                                                textAlign={"left"}
                                                fontWeight={700}
                                                className="input_label"
                                            >
                                                {PageGeneralTexts.GENERAL_STAR}
                                            </Typography>
                                            {PageGeneralTexts.EMAIL}
                                        </h5>
                                        <OutlinedInput
                                            sx={{
                                                background: "#e6f2f7",
                                                height: "52px",
                                                padding: "10px 15px",
                                                fontSize: "17.3879px",
                                                width: "100%",
                                            }}
                                            type={PaymentEnums.EMAIL_TYPE}
                                            placeholder={PaymentEnums.EMAIL}
                                            {...register(PaymentEnums.EMAIL_ID)}
                                            className={
                                                errors?.email
                                                    ? "input-error outlinedInput"
                                                    : " outlinedInput"
                                            }
                                            id={PaymentEnums.EMAIL_ID}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#2457A0",
                                        paddingX: "40px",
                                        paddingY: "22px",
                                        fontSize: "26px",
                                        color: "white",
                                        borderRadius: "45px",
                                        marginTop: "50px",
                                        width: "200px",
                                        height: "81px",
                                    }}
                                    className="submit_checkout_form"
                                >
                                    {PageGeneralTexts.SAVE}
                                </Button>
                            </form>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            md={6}
                            justifyContent={"end"}
                            display={"flex"}
                        >
                            <h1 className="h1-header mobile-header">
                                {PageGeneralTexts.CHECKOUT}
                            </h1>
                            <Card
                                sx={{
                                    width: "460px",
                                    height: "fit-content",
                                    textAlign: "left",
                                    padding: "15px 30px",
                                    boxShadow:
                                        "0px 0px 14px 0px rgba(0, 148, 255, 0.38)",
                                }}
                                className="vmr-card"
                            >
                                <h6 className="title-header">
                                    {PageGeneralTexts.VIRTUAL_LAB_ROOM}
                                </h6>
                                <hr className="hr" />
                                <Grid
                                    container
                                    spacing={2}
                                    className="total-grid initial"
                                >
                                    <Grid
                                        item
                                        xs={7}
                                        md={10}
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "left",
                                            color: "#666666",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details">
                                            {PageGeneralTexts.INITIAL_PAYMENT}
                                        </p>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        md={2}
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                            lineHeight: "21px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "right",
                                            color: "#2457a0",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details">
                                            ₪{payments.initial_payment}
                                        </p>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={2}
                                    className="total-grid initial"
                                >
                                    <Grid
                                        item
                                        xs={7}
                                        md={10}
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "left",
                                            color: "#666666",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details">
                                            {PageGeneralTexts.MONTHLY_PAYMENT}
                                        </p>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        md={2}
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600",
                                            lineHeight: "21px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "right",
                                            color: "#2457a0",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details">
                                            ₪{payments.recurring_payment}
                                        </p>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={2}
                                    className="total-grid"
                                >
                                    <Grid
                                        item
                                        xs={7}
                                        md={10}
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "left",
                                            color: "#666666",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details">(VAT Included)</p>
                                    </Grid>

                                    <Grid item xs={4} md={2}></Grid>
                                </Grid>

                                <hr className="hr" />

                                <Grid
                                    container
                                    spacing={2}
                                    className="total-grid"
                                >
                                    <Grid
                                        item
                                        xs={7}
                                        md={9}
                                        sx={{
                                            fontFamily: "AssistantRegular",
                                            fontSize: "24px",
                                            fontWeight: "500",
                                            lineHeight: "22px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "left",
                                            color: "#666666",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details">
                                            {PageGeneralTexts.TOTAL}
                                        </p>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        md={3}
                                        sx={{
                                            fontFamily: "AssistantRegular",
                                            fontSize: "40px",
                                            fontWeight: "600",
                                            lineHeight: "21px",
                                            letterSpacing: "-0.015em",
                                            textAlign: "right",
                                            color: "#2457a0",
                                        }}
                                        className="vmr-details-label"
                                    >
                                        <p className="vmr-details-sum">
                                            ₪{payments.total}
                                        </p>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Box>
                    <img
                        style={{ width: "100%", height: "180px" }}
                        src="images/purchase-curve-two.png"
                        alt="banner"
                    />
                </Box>
                <Grid
                    container
                    item
                    xs={11}
                    md={10.5}
                    margin={"auto"}
                    padding={5}
                    className="pay_with_credit"
                >
                    <h4 className="title-header">
                        {PageGeneralTexts.PAY_WITH_CREDITCARD}
                    </h4>

                    <Grid
                        container
                        spacing={1}
                        display={"flex"}
                        direction={"row"}
                    >
                        <Grid item xs={12} md={6}>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: 350,
                                    border: "2px solid #666 !important",
                                    borderRadius: "4px",
                                    padding: "10px 15px",
                                }}
                                className="termsBox-container"
                            >
                                <Box
                                    sx={{
                                        height: 330,
                                        padding: "10px 15px",
                                        fontSize: "17.3879px",
                                        borderRadius: "4px",
                                        width: "97%",
                                    }}
                                    overflow="auto"
                                    className="termsBox"
                                >
                                    <h5 className="terms-header">
                                        {PageGeneralTexts.TERMS_AND_CONDITIONS}
                                    </h5>
                                    <Typography
                    variant="body1"
                    fontSize={"20px"}
                    margin={0}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={5}
                    fontWeight={300}
                >
                    <ul>
                        <li>
                            Once a Virtual Lab Room has been rented for a fixed
                            period of time and the data has been acquired, the
                            user is free to perform any analysis in the Virtual
                            Lab Room environment, disconnected from the
                            internet. 
                        </li>
                        <li>
                            Using the research room is personal and can be given
                            to only one individual or one research group
                            belonging to a single organization. 
                        </li>
                        <li>
                            Passing access information to a third party is not
                            permitted. 
                        </li>
                        <li>
                            When the agreement period for the use of the
                            research room expires, the user is given the option
                            to extend the agreement or to terminate it and
                            download the research material without the raw data.
                        </li>
                        <li>
                            This service is not allowed for employees of a
                            pharmaceutical or medical equipment company or for
                            users who perform work for a pharmaceutical or
                            medical equipment company.
                        </li>
                    </ul>
                </Typography>
                                    {/* {data.map((value, key) => (
                                        <Grid container>
                                            <Grid item xs={12} md={12}>
                                                <ListItem
                                                    key={key}
                                                    component="div"
                                                    disablePadding
                                                >
                                                    <ListItemButton className="listItemTextKey-container">
                                                        <ListItemText
                                                            className="listItemTextKey"
                                                            primary={
                                                                key +
                                                                1 +
                                                                ". " +
                                                                "Clause " +
                                                                (key + 1)
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <ListItem
                                                    key={key}
                                                    component="div"
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemText
                                                            className="listItemText"
                                                            primary={value}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    ))} */}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            display={"flex"}
                            direction={"row"}
                            className="terms-wrapper"
                        >
                            <Grid item xs={1.5} md={0.25}>
                                <Checkbox
                                    disableRipple
                                    {...termsAndConditions}
                                    sx={{
                                        alingItems: "left",
                                        "&.Mui-checked": {
                                            color: "#666666",
                                        },
                                        paddingTop: "15px",
                                    }}
                                    onClick={(e) => handleTermsChange(e)}
                                    checked={termsChecked}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={10.5}
                                md={11}
                                sx={{
                                    textAlign: "left",
                                }}
                            >
                                <p className="terms-conditions">
                                    {PageGeneralTexts.TERMS_CONDITIONS_ONE}
                                    <Link
                                        to="/terms"
                                        target="_blank"
                                        className="terms-link"
                                    >
                                        {PageGeneralTexts.TERMS_CONDITIONS_TWO}
                                    </Link>
                                    {PageGeneralTexts.TERMS_CONDITIONS_THREE}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    {paymentURI && (
                        <>
                            <Grid item xs={12} md={8} lg={6} position='relative'>
                                <Typography variant="h6" gutterBottom>
                                    Credit Card Payment
                                </Typography>
                                {!termsChecked && (
                                    <div style={{ 
                                        position: "absolute", top: 0, left: 0, width: "1000px", height: "100%", 
                                        backgroundColor: "rgba(155,155,155,0.5)", backdropFilter: "blur(2px)", zIndex: 1000, 
                                    }}></div>
                                )}
                                <iframe
                                    onLoad={handleIframeLoad}
                                    src={paymentURI}
                                    title="Credit Card Payment"
                                    width="100%"
                                    style={{ border: "none" }}
                                />

                            </Grid>
                        </>
                    )}
                </Grid>
                {loaderState ? (
                    <Grid
                        container
                        position={"fixed"}
                        left={"30px"}
                        top={0}
                        width={"100%"}
                        height={"100%"}
                    >
                        <Loader loaderState={loaderState}></Loader>
                    </Grid>
                ) : (
                    ""
                )}
            </Container>
        </>
    );
};
