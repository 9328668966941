import React, { useEffect } from "react";
import "./Terms.scss";
import { Box, Grid, Typography } from "@mui/material";
export const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Grid
            container
            xs={12}
            sx={{ backgroundColor: "#F1F9FE" }}
            className="terms-container"
        >
            <Grid item xs={12}>
                <Box>
                    <img
                        style={{ width: "100%", backgroundSize: "cover" }}
                        src="images/main-banner.png"
                        alt="banner"
                    />
                </Box>
            </Grid>
            <Grid
                container
                xs={10}
                display={"flex"}
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems={"left"}
                sx={{ margin: "auto" }}
            >
                <Typography
                    variant="h1"
                    fontSize={"70px"}
                    margin={0}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={0}
                    fontWeight={700}
                    className="terms-title"
                >
                    Terms And Conditions
                </Typography>
                <Typography
                    variant="body1"
                    fontSize={"40px"}
                    margin={0}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={0}
                    fontWeight={500}
                    className="terms-sub-title"
                >
                    Agreement overview
                </Typography>
                <Typography
                    variant="body1"
                    fontSize={"32px"}
                    margin={0}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={10}
                    fontWeight={300}
                    className="terms-date-title"
                >
                    16.07.2023
                </Typography>
                <Typography
                    variant="body1"
                    fontSize={"24px"}
                    margin={0}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={5}
                    fontWeight={300}
                >
                    <ul>
                        <li>
                            Once a Virtual Lab Room has been rented for a fixed
                            period of time and the data has been acquired, the
                            user is free to perform any analysis in the Virtual
                            Lab Room environment, disconnected from the
                            internet. 
                        </li>
                        <li>
                            Using the research room is personal and can be given
                            to only one individual or one research group
                            belonging to a single organization. 
                        </li>
                        <li>
                            Passing access information to a third party is not
                            permitted. 
                        </li>
                        <li>
                            When the agreement period for the use of the
                            research room expires, the user is given the option
                            to extend the agreement or to terminate it and
                            download the research material without the raw data.
                        </li>
                        <li>
                            This service is not allowed for employees of a
                            pharmaceutical or medical equipment company or for
                            users who perform work for a pharmaceutical or
                            medical equipment company.
                        </li>
                    </ul>
                </Typography>
                <Typography
                    variant="body1"
                    fontSize={"24px"}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={5}
                    fontWeight={700}
                    className="disclaimer"
                >
                    <a href="/טיוטת הסכם- שימוש במאגר נתונים.pdf" target="_blank">
                    The full agreement can be found here.
                    </a>
                </Typography>
                <Typography
                    variant="body1"
                    fontSize={"24px"}
                    color={"#2457a0"}
                    textAlign={"left"}
                    mb={20}
                    fontWeight={300}
                    className="disclaimer"
                >
                    Disclaimer: Notice of Privacy Practices. Your privacy is
                    important to us. We are committed to protecting the privacy
                    of your personal information. We will not share your
                    personal information with any third party unless we are
                    obligated to do so by law. We will take reasonable steps to
                    protect your personal information from unauthorized access,
                    use, or disclosure.
                </Typography>
            </Grid>
        </Grid>
    );
};
