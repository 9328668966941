import React from "react";
import "./MobileHeader.scss";
import {
    Box,
    List,
    Divider,
    Drawer,
    Tab,
    Grid,
    Typography,
    Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { PageGeneralTexts } from "commons/enums";
import { Link } from "react-router-dom";
import { Login, Logout } from "@mui/icons-material";
import { AuthService } from "services";

export const MobileHeader = ({
    researcherLogged,
}: {
    researcherLogged: boolean;
}) => {
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer =
        (anchor: string = "right", open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setState({ ...state, [anchor]: open });
        };

    const list = (anchor: string = "right") => (
        <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            id="hamburger-list"
            paddingLeft={3}
            height={"90vh"}
        >
            <List>
                <Grid
                    container
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"end"}
                >
                    <button
                        className="close"
                        onClick={toggleDrawer("right", false)}
                    >
                        <img
                            src="images/xclose.svg"
                            alt="logo"
                            className="logo"
                        />
                    </button>
                </Grid>
                 <Grid
                    container
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"space-between"}
                    height={"90vh"}
                >
                    <Grid item xs={12}>
                        <Link to="/register" className="link-to">
                            <div className="img-holder">
                                <img
                                    src="images/register.svg"
                                    alt="logo"
                                    className="logo"
                                />
                            </div>
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.REGISTER_HEADER}
                            </Typography>
                        </Link>
                        <Link to="/profile" className="link-to mb">
                            <div className="img-holder">
                                <img
                                    src="images/profilecircle.svg"
                                    alt="logo"
                                    className="logo"
                                />
                            </div>
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.PROFILE}
                            </Typography>
                        </Link>

                        <Link to="/" className="link-to">
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.HOME_LINK}
                            </Typography>
                        </Link>
                        <Link to="/terms" className="link-to">
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.TERMS_LINK}
                            </Typography>
                        </Link>
                        <Link to="/faq" className="link-to">
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.FAQ_LINK}
                            </Typography>
                        </Link>
                        <Link to="/theData" className="link-to">
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.DISCLAIMER_LINK}
                            </Typography>
                        </Link>
                        <Link to="/contactUs" className="link-to mb">
                            <Typography
                                variant="body1"
                                fontSize={"20px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                mb={0}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.CONTACT_LINK}
                            </Typography>
                        </Link>

                        <Button
                                sx={{
                                    backgroundColor: "#2457A0",
                                    paddingX: "25px",
                                    paddingY: "7px",
                                    fontSize: "22px",
                                    color: "white",
                                    borderRadius: "45px",
                                    marginTop: "0",
                                    marginBottom: "0",
                                }}
                                className="login-logout"
                                startIcon={
                                    researcherLogged ? (
                                        <Logout style={{ fontSize: "28px" }} />
                                    ) : (
                                        <Login style={{ fontSize: "28px" }} />
                                    )
                                }
                                size="large"
                            >
                                {researcherLogged ? (
                                    <p
                                        className="log"
                                        onClick={() => {
                                            AuthService.getInstance().logout();
                                        }}
                                    >
                                        {PageGeneralTexts.LOGOUT_HEADER}
                                    </p>
                                ) : (
                                    <Link to="/login" style={{color:"white", textTransform:"none"}}>
                                        {PageGeneralTexts.LOGIN}
                                    </Link>
                                )}
                        </Button>                        
                    </Grid>

                </Grid>
            </List>
        </Box>
    );

    return (
        <div className="mobile-header">
            <a onClick={toggleDrawer("right", true)}>
                <Tab
                    className="sidenav"
                    icon={<MenuIcon />}
                    disabled
                    aria-label="Logo"
                />
            </a>

            <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
            >
                {list()}
            </Drawer>
        </div>
    );
};
