import { TerminateResponseType, TerminationConfirmation } from "commons/types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ResearcherService } from "services/api/ResearcherService.service";
import "./TerminationPopUp.scss";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, CardMedia, Grid, Tab, Typography } from "@mui/material";
import { RateUs } from "./rateUs/RateUs";
import { PageGeneralTexts } from "commons/enums";

export const TerminationPopUp = ({
    profile,
    toggle,
}: TerminationConfirmation) => {
    const [toggleSuccess, setToggleSuccess] = useState(false);
    const [toggleRate, setToggleRate] = useState(false);

    const putTermination = async () => {
        const result: TerminateResponseType | string =
            await ResearcherService.getInstance().endSubscription(profile._id);
        if (typeof result === "object" && "message" in result) {
            toast.success(result.message);
            setToggleSuccess(!toggleSuccess);
        }
        if (typeof result === "string") {
            toast.error(result);
        }
    };

    return (
        <>
            <Grid
                container
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: "100%" }}
            >
                <Grid xs={6} md={5} container className="termination-container">
                    <Grid
                        container
                        display={"flex"}
                        direction={"column"}
                        paddingTop={"8%"}
                        paddingBottom={"6%"}
                        paddingX={"10%"}
                        xs={11.99}
                        sx={{
                            backgroundColor: "#EBF7FF",
                            zIndex: 1,
                            borderRadius: "5px",
                            position: "relative",
                        }}
                        className="termination_grid"
                    >
                        {!toggleRate ? (
                            <Grid >
                                {!toggleSuccess ? (
                                    <Grid container>
                                        <Grid item marginBottom={7} className="notice_grid">
                                            <Typography
                                                variant="body1"
                                                fontSize={"24px"}
                                                margin={0}
                                                marginX={0}
                                                marginBottom={0}
                                                color={"#2457A0"}
                                                textAlign={"left"}
                                                fontWeight={300}
                                                className="notice"
                                            >
                                                {
                                                    PageGeneralTexts.TERMINATION_NOTICE
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid className="confirm_cancel">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#2457A0",
                                                    paddingX: "57px",
                                                    paddingY: "9px",
                                                    fontSize: "20px",
                                                    color: "white",
                                                    borderRadius: "45px",
                                                    border: "2px solid #2457A0",
                                                    textTransform: "none",
                                                    fontWeight: 400,
                                                    marginRight: 3,
                                                }}
                                                onClick={putTermination}
                                                className="confirm"
                                            >
                                                {PageGeneralTexts.CONFIRM}
                                            </Button>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="cancel"
                                                sx={{
                                                    backgroundColor:
                                                        "transparent",
                                                    paddingX: "57px",
                                                    paddingY: "9px",
                                                    fontSize: "20px",
                                                    color: "#2457A0",
                                                    borderRadius: "45px",
                                                    border: "2px solid #2457A0",
                                                    textTransform: "none",
                                                    fontWeight: 400,
                                                    marginRight: 3,
                                                    ":hover": {
                                                        backgroundColor:
                                                            "transparent !important",
                                                    },
                                                }}
                                                onClick={toggle}
                                            >
                                                {PageGeneralTexts.CANCEL}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container>
                                        <Typography
                                            variant="body1"
                                            fontSize={"24px"}
                                            margin={0}
                                            marginX={0}
                                            marginBottom={0}
                                            color={"#2457A0"}
                                            textAlign={"left"}
                                            fontWeight={300}
                                            className="termination_files"
                                        >
                                            {
                                                PageGeneralTexts.TERMINATION_FILES_LOCATION
                                            }
                                        </Typography>

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#2457A0",
                                                paddingX: "57px",
                                                paddingY: "9px",
                                                fontSize: "20px",
                                                color: "white",
                                                borderRadius: "45px",
                                                border: "2px solid #2457A0",
                                                textTransform: "none",
                                                fontWeight: 400,
                                                margin: "auto",
                                                marginTop: "25px",
                                                ":hover": {
                                                    backgroundColor:
                                                        "#2457A0 !important",
                                                },
                                            }}
                                            className="confirm"
                                            onClick={() => {
                                                setToggleRate(!toggleRate);
                                            }}
                                        >
                                            {PageGeneralTexts.CONFIRM}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid className="termination_grid" item>
                                <RateUs profile={profile} toggle={toggle} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
