import {
    PageGeneralTexts,
    PageNavigation,
    ResearcherStatus,
    StorageKeys,
    ToasterMessagesEnums,
} from "commons/enums";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService, LocalStorageService } from "services";
import "./Otp.scss";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Button,
    Grid,
    Link,
    Tab,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { OTP } from "commons/types";
import { AuthGuardService } from "services/guard";

export const Otp = () => {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const local = new LocalStorageService();

    const CssTextField = styled(TextField)({
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: error ? "#F00" : "#666",
            },
            "&:hover fieldset": {
                borderColor: "#666",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#666",
            },
        },
    });

    const checkOpt = async () => {
        try {
            const result = await AuthService.getInstance().validateOTP({
                code: otp,
            } as OTP);
            if (result) {
                toast.success(ToasterMessagesEnums.OTP_IS_VALID);
                const researcherPayload =
                    AuthGuardService.getInstance().getCurrentResearcherPayload();
                if (
                    researcherPayload.status ===
                        ResearcherStatus.APPROVED_PAID ||
                    researcherPayload.status ===
                        ResearcherStatus.APPROVED_PAID_NOT_ACTIVE
                ) {
                    return navigate("/profile", { replace: true });
                } else {
                    return navigate("/purchase", { replace: true });
                }
            } else {
                toast.error(ToasterMessagesEnums.OTP_IS_FAILED);
                setError(true);
            }
        } catch (error) {
            toast.error(ToasterMessagesEnums.OTP_IS_FAILED);
        }
    };

    const goToRegister = () => {
        local.removeItemFromSessionStorage(StorageKeys.OTPToken);
        navigate("/register", {
            replace: true,
        });
    };

    const resendOtp = async () => {
        try {
            setError(false);
            const result = await AuthService.getInstance().resendOTP();
            if (result) {
                toast.success(ToasterMessagesEnums.OTP_HAS_RESEND_SUCCESSFUL);
            } else {
                toast.error(ToasterMessagesEnums.OTP_HAS_RESEND_FAILED);
                AuthService.getInstance().logout();
            }
        } catch (error) {
            toast.error(ToasterMessagesEnums.OTP_HAS_RESEND_FAILED);
        }
    };

    const skipInputs = (value: string, index: number) => {
        if (index >= 1 && index < 6 && value) {
            const inputFocus = document.getElementById(`otp${index + 1}`);
            inputFocus?.focus();
        }
    };

    const replaceCharAt = (
        originalString: string,
        index: number,
        replacement: string
    ) => {
        return (
            originalString.substring(0, index) +
            replacement +
            originalString.substring(index + replacement.length)
        );
    };
    const inputCellOnChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        inputIndex: number
    ) => {
        setError(false);
        event.preventDefault();
        let tempValue = otp;
        tempValue = replaceCharAt(
            tempValue,
            inputIndex - 1,
            event.target.value
        );
        setOtp(tempValue);
        skipInputs(event.target.value, inputIndex);
    };
    return (
        <>
            <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                xs={12}
                sx={{
                    backgroundImage: 'url("images/login_bg.svg")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    xs={11}
                    md={6}
                    className="otp"
                    margin={"auto"}
                    marginY={20}
                >
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        paddingX={"10%"}
                        paddingY={"12%"}
                        xs={11.99}
                        sx={{ backgroundColor: "#ebf7ff", zIndex: 1 }}
                        className="opt_container"
                    >
                        <Grid
                            item
                            xs={1}
                            md={1}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                marginRight: 3,
                            }}
                        >
                            <Link
                                className="go_back"
                                onClick={() => {
                                    AuthService.getInstance().logout();
                                }}
                            >
                                <Tab
                                    icon={<ArrowBackIcon />}
                                    disabled
                                    aria-label="Logo"
                                />
                            </Link>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            md={9}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography
                                variant="h1"
                                fontSize={"24px"}
                                margin={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                fontWeight={500}
                                width={"100%"}
                                lineHeight={"35px"}
                                marginBottom={7}
                            >
                                {PageGeneralTexts.ENTER_OTP}
                            </Typography>

                            <Grid
                                container
                                display={"flex"}
                                direction={"row"}
                                justifyContent={"space-between"}
                               
                            >
                                <Grid
                                    container
                                    xs={12}
                                    md={5.825}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    marginBottom={2.2}
                                    
                                >
                                    <Grid item md={3.5} xs={3.5}>
                                        <TextField
                                            variant="outlined"
                                            name="opt1"
                                            inputProps={{ maxLength: 1 }}
                                            className={error ? "error" : ""}
                                            style={{ textAlign: "center" }}
                                            id="otp1"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => inputCellOnChange(event, 1)}
                                        />
                                    </Grid>
                                    <Grid item md={3.5} xs={3.5}>
                                        <TextField
                                            variant="outlined"
                                            name="opt2"
                                            inputProps={{ maxLength: 1 }}
                                            className={error ? "error" : ""}
                                            style={{ textAlign: "center" }}
                                            id="otp2"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => inputCellOnChange(event, 2)}
                                        />
                                    </Grid>
                                    <Grid item md={3.5} xs={3.5}>
                                        <TextField
                                            variant="outlined"
                                            name="opt3"
                                            inputProps={{ maxLength: 1 }}
                                            className={error ? "error" : ""}
                                            style={{ textAlign: "center" }}
                                            id="otp3"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => inputCellOnChange(event, 3)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    xs={12}
                                    md={5.825}
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                >
                                    <Grid item md={3.5} xs={3.5}>
                                        <TextField
                                            variant="outlined"
                                            name="opt4"
                                            inputProps={{ maxLength: 1 }}
                                            className={error ? "error" : ""}
                                            style={{ textAlign: "center" }}
                                            id="otp4"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => inputCellOnChange(event, 4)}
                                        />
                                    </Grid>
                                    <Grid item md={3.5} xs={3.5}>
                                        <TextField
                                            variant="outlined"
                                            name="opt5"
                                            inputProps={{ maxLength: 1 }}
                                            className={error ? "error" : ""}
                                            style={{ textAlign: "center" }}
                                            id="otp5"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => inputCellOnChange(event, 5)}
                                        />
                                    </Grid>
                                    <Grid item md={3.5} xs={3.5}>
                                        <TextField
                                            variant="outlined"
                                            name="opt6"
                                            inputProps={{ maxLength: 1 }}
                                            className={error ? "error" : ""}
                                            style={{ textAlign: "center" }}
                                            id="otp6"
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => inputCellOnChange(event, 6)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} display={"flex"} marginTop={5}>
                                {error ? (
                                    <Typography
                                        variant="body1"
                                        fontSize={"18px"}
                                        margin={0}
                                        color={"#F00"}
                                        textAlign={"left"}
                                        fontWeight={400}
                                        width={"100%"}
                                        lineHeight={"35px"}
                                    >
                                        {PageGeneralTexts.INVALID_OTP}
                                    </Typography>
                                ) : (
                                    <Link
                                        fontSize={"20px"}
                                        fontWeight={400}
                                        onClick={() => {
                                            resendOtp();
                                            setError(false);
                                        }}
                                    >
                                        {PageGeneralTexts.DID_NOT_RECEIVE_OTP}
                                    </Link>
                                )}
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                display={"flex"}
                                marginTop={10}
                            >
                                <Grid item className="send_otp">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#2457A0",
                                            paddingX: "57px",
                                            paddingY: "9px",
                                            fontSize: "20px",
                                            color: "white",
                                            borderRadius: "45px",
                                            textTransform: "none",
                                            fontWeight: 400,
                                            marginRight: 3,
                                            marginBottom: 3,
                                        }}
                                        onClick={() => {
                                            checkOpt();
                                        }}
                                    >
                                        {PageGeneralTexts.SEND}
                                    </Button>
                                </Grid>

                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                    className="other_actions"
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"20px"}
                                        margin={0}
                                        marginX={0}
                                        marginBottom={0}
                                        color={"#666"}
                                        textAlign={"left"}
                                        fontWeight={500}
                                    >
                                        {PageGeneralTexts.NEW_USER}
                                    </Typography>

                                    <Link
                                        sx={{
                                            marginLeft: 1,
                                            fontSize: "20px",
                                        }}
                                        onClick={() => {
                                            navigate(PageNavigation.REGISTER);
                                        }}
                                    >
                                        {PageGeneralTexts.REGISTER}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
