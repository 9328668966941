import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export const ArrowSvgIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            viewBox="0 0 18 10"
            {...props}
            style={{
                fill: "none",
            }}
        >
            <path
                d="M16.92 1.28906L10.4 7.80906C9.62996 8.57906 8.36996 8.57906 7.59996 7.80906L1.07996 1.28906"
                stroke="#2457A0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};
