import { LocalStorageService } from "services/LocalStorage.service";
const LOGIN_REDIRECT_ERRORS = ["at6", "at7", "at9"];
const LOGIN_REDIRECT_DESTINATION = "/login";

export const RedirectErrors = async (
    code: string,
    localStorageService?: LocalStorageService
) => {
    if (LOGIN_REDIRECT_ERRORS.includes(code) && localStorageService) {
        //Clear access , refresh or OTP tokens before redirecting
        localStorageService.clearLocalStorage();
        localStorageService.clearSessionStorage();
        window.location.href = LOGIN_REDIRECT_DESTINATION;
    }
};
