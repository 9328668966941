export const subjectOptions = [
    {
        value: "OPTION_ONE",
        label: "Request to change/enlarge Virtual Lab Room",
    },
    { value: "OPTION_TWO", label: "Get files from the 'To Export' folder" },
    { value: "OPTION_THREE", label: "Financial" },
    { value: "OPTION_FOUR", label: "Technical Support" },
    { value: "OPTION_FIVE", label: "Other" },
];
