import { FAQ, FaqPageResponseType, PaymentSettingsResponseType, SiteSettings } from "commons/types";
import { LocalStorageService } from "services/LocalStorage.service";
import GlobalApiService from "./GlobalApi.service";
import { RequestEnum, Routes } from "commons/enums";

export class SettingsService {
    private static instance: SettingsService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new SettingsService();
        }
        return this.instance;
    }

    async fetchFaq(): Promise<FaqPageResponseType | string> {
        const faqs: FaqPageResponseType | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.GET_FAQ
            );

        let res = {} as FaqPageResponseType | string;

        if (typeof faqs === "object" && "faq" in faqs) {
            res = faqs;
        }
        if (typeof faqs === "string") {
            res = faqs;
        }
        return res;
    }

    async getPaymentSettings(): Promise<PaymentSettingsResponseType | string> {
        const paymentSettings: PaymentSettingsResponseType | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.GET_PAYMENT_SETTINGS
            );

        let res = {} as PaymentSettingsResponseType | string;

        if (typeof paymentSettings === "object") {
            res = paymentSettings;
        }
        if (typeof paymentSettings === "string") {
            res = paymentSettings;
        }
        return res;
    }

    async getSiteSettings(): Promise<boolean> {
        const result: SiteSettings | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                Routes.GET_SETTINGS
            );

        let res = {} as SiteSettings | string;
        if (typeof result === "object") {
            res = result;
        }
        if (typeof result === "string") {
            res = result;
        }

        this.localStorageService.setItemInLocalStorage(
            "smd",
            JSON.stringify(res)
        );

        return !!res;
    }
}
