import jwt_decode from "jwt-decode";
import { StorageKeys } from "commons/enums";
import { ResearcherPayload } from "commons/interfaces";
import { LocalStorageService } from "services/LocalStorage.service";
import { isEmptyObject } from "commons/utils";

export class AuthGuardService {
    private static instance: AuthGuardService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthGuardService();
        }
        return this.instance;
    }

    isResearcherOTPAuthenticated() {
        const otpToken = this.localStorageService.getToken(
            StorageKeys.OTPToken
        );
            
        return !!otpToken;
    }

    getCurrentResearcherPayload(): ResearcherPayload {
        const token: string =
            this.localStorageService.getToken(StorageKeys.AccessToken) || "";
        let res: ResearcherPayload = {} as ResearcherPayload;
        if (token !== "") {
            res = jwt_decode(token);
        }
        return res;
    }

    isResearcherLoggedIn() {
        const researcherPayload = this.getCurrentResearcherPayload();
        if (isEmptyObject(researcherPayload)) {
            return false;
        }
        return true;
    }
}
