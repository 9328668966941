import { ProfileResponseType, TransactionsResponseType } from "commons/types";
import { Researcher, TransactionModel } from "Models";
import React, { useEffect, useState } from "react";
import { ResearcherService } from "services/api/ResearcherService.service";
import "./Profile.scss";
import { toast } from "react-toastify";
import { TransactionsService } from "services/api/TransactionsService.service";
import { TransactionHistory } from "./transactions/TransactionsHistory";
import { VirtualLabRoom } from "./virtualLabRoom/VirtualLabRoom";
import { Modal, Box, Grid, CardMedia, Typography, Button } from "@mui/material";
import { ModalStyle } from "commons/GlobalModalStyle";
import { DataFilePopUp } from "./dataFilePopUp/DataFilePopUp";
import {
    ModalTypeEnum,
    PageGeneralTexts,
    ToasterMessagesEnums,
    TransactionSubtype,
} from "commons/enums";
import { TerminationPopUp } from "./terminationPopUp/TerminationPopUp";
import { AuthGuardService } from "services/guard";

export const Profile = () => {
    const [profile, setProfile] = useState({} as Researcher);
    const [transactions, setTransactions] = useState([] as TransactionModel[]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = ModalStyle;
    const [modalType, setModalType] = useState("");

    const getUser = async () => {
        const researcherPayload =
            AuthGuardService.getInstance().getCurrentResearcherPayload();
        await getResearcherProfile(researcherPayload.researcherId);
        await getResearcherTransactions();
    };

    const getResearcherProfile = async (id: string) => {
        const result: ProfileResponseType | string =
            await ResearcherService.getInstance().getResearcherProfile(id);
        if (typeof result === "object") {
            setProfile(result.profile);
        }
        if (typeof result === "string") {
            toast.error(ToasterMessagesEnums.FAILED_TO_GET_PROFILE);
        }
    };

    const getResearcherTransactions = async () => {
        const result: TransactionsResponseType | string =
            await TransactionsService.getInstance().getResearcherTransactions();
        if (typeof result === "object" && "transactions" in result) {
            const responseTransactions = result.transactions?.filter(t => t.subtype !== TransactionSubtype.REQ);
            setTransactions(responseTransactions);
        }
        if (typeof result === "string") {
            toast.error(ToasterMessagesEnums.FAILED_TO_GET_TRANSACTIONS);
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    return (
        <>
            <Grid container xs={12} className="profile-page">
                <CardMedia
                    sx={{ backgroundColor: "#F9FBFC" }}
                    component="img"
                    height="auto"
                    image="images/profile-banner.svg"
                    alt="banner"
                />
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-between"}
                    paddingY={5}
                    sx={{ backgroundColor: "#F9FBFC" }}
                    className="researcher_details"
                >
                    <Grid
                        item
                        xs={6}
                        md={8}
                        paddingLeft={"5%"}
                        direction={"column"}
                        justifyContent={"end"}
                    >
                        <Typography
                            variant="body1"
                            fontSize={"32px"}
                            marginX={0}
                            marginTop={0}
                            color={"#2457a0"}
                            textAlign={"left"}
                            width={"100%"}
                            fontWeight={600}
                            className="details_bold"
                        >
                            {profile?.firstName + " " + profile?.lastName}
                        </Typography>
                        <Typography
                                variant="body1"
                                fontSize={"24px"}
                                marginX={0}
                                marginTop={0}
                                color={"#2457a0"}
                                textAlign={"left"}
                                width={"100%"}
                                fontWeight={400}
                                className="details_regular"
                            >
                                {profile?.email}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontSize={"24px"}
                                marginX={0}
                                marginTop={0}
                                color={"#2457a0"}
                                textAlign={"left"}
                                width={"100%"}
                                fontWeight={400}
                                className="details_regular"
                            >
                                {profile?.phone}
                            </Typography>
                            <Typography
                                variant="body1"
                                fontSize={"24px"}
                                marginX={0}
                                marginTop={0}
                                color={"#2457a0"}
                                textAlign={"left"}
                                width={"100%"}
                                fontWeight={400}
                                className="details_regular"
                            >
                                {profile?.personalId}
                            </Typography>
                        <Typography textAlign={"left"}>
                            <a href={PageGeneralTexts.P81_LOGIN} target="_blank">
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => {
                                    }}
                                    sx={{
                                        backgroundColor: "#2457A0",
                                        paddingX: "40px",
                                        paddingY: "15px",
                                        fontSize: "26px",
                                        color: "white",
                                        borderRadius: "45px",
                                        marginTop: "100px",
                                        marginBottom: "0",
                                        ":hover": {
                                            backgroundColor: "#2457A0 !important",
                                        },
                                        textTransform: "none",
                                    }}
                                >
                                    {PageGeneralTexts.ACCESS_MACHINE}
                                </Button>
                            </a>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        xs={6}
                        md={4}
                        display={"flex"}
                        justifyContent={"end"}
                        className="right-details"
                    >
                        <Grid
                            item
                            display={"flex"}
                            direction={"column"}
                            mt={6}
                            xs={11}
                            md={8}
                        >
                        <Typography
                            variant="body1"
                            fontSize={"24px"}
                            marginX={0}
                            marginTop={0}
                            color={"#2457a0"}
                            textAlign={"left"}
                            width={"60%"}
                            fontWeight={400}
                            className="details_regular"
                        >
                            {profile?.businessId ? profile?.businessId : ""}
                        </Typography>
                        <Typography
                            variant="body1"
                            fontSize={"24px"}
                            marginX={0}
                            marginTop={0}
                            color={"#2457a0"}
                            textAlign={"left"}
                            width={"60%"}
                            fontWeight={400}
                            className="details_regular"
                        >
                            {profile?.organization
                                ? profile?.organization
                                :""}
                        </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ backgroundColor: "#EEF9F7" }}
                    paddingY={10}
                    className="datafile_container"
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                        marginLeft={"5%"}
                        display={"flex"}
                        alignItems={"center"}
                    >
                        <Typography
                            variant="body1"
                            fontSize={"32px"}
                            marginX={0}
                            marginTop={0}
                            color={"#2457a0"}
                            textAlign={"left"}
                            width={"60%"}
                            fontWeight={400}
                            className="vm_data_file"
                        >
                            {PageGeneralTexts.VM_DATA_FILE}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        xs={12}
                        md={4}
                        display={"flex"}
                        justifyContent={"end"}
                        paddingRight={"5%"}
                        className="data_file"
                    >
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={
                                    !!profile?.virtualMachine
                                        ?.isDataDiskAttached
                                }
                                onClick={() => {
                                    setModalType(
                                        ModalTypeEnum.DATA_FILE_CONFIRMATION
                                    );
                                    handleOpen();
                                }}
                                sx={{
                                    backgroundColor: "#2457A0",
                                    paddingX: "40px",
                                    paddingY: "25px",
                                    fontSize: "26px",
                                    color: "white",
                                    borderRadius: "45px",
                                    marginTop: "0",
                                    marginBottom: "0",
                                    ":hover": {
                                        backgroundColor: "#2457A0 !important",
                                    },
                                }}
                            >
                                {PageGeneralTexts.GET_DATA_FILE}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <VirtualLabRoom profile={profile} />
                </Grid>
                <Grid
                    xs={12}
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    paddingTop={"10%"}
                    marginBottom={10}
                    sx={{
                        backgroundImage:
                            "url('images/profile-payment-top.svg')",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                    }}
                    className="payment_history_vector"
                >
                    <TransactionHistory transactions={transactions} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundImage:
                            "url('images/profile-payment-bottom.svg')",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundColor: "#EEF9F7",
                    }}
                    display={"flex"}
                    justifyContent={"end"}
                    paddingX={10}
                    paddingTop={20}
                    paddingBottom={10}
                    height={"auto"}
                    className="end_sub"
                >
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => {
                            setModalType(
                                ModalTypeEnum.TERMINATION_CONFIRMATION
                            );
                            handleOpen();
                        }}
                        sx={{
                            backgroundColor: "#EEF9F7",
                            paddingX: "45px",
                            paddingY: "20px",
                            fontSize: "24px",
                            borderRadius: "45px",
                            border: "2px solid #2457A0",
                            color: "#2457A0",
                            marginTop: "0",
                            marginBottom: "0",
                            margin: "155px 0 20px 0",
                            height: "71px",
                            ":hover": {
                                backgroundColor: "transparent !important",
                                boxShadow: "inherit !important",
                            },
                        }}
                    >
                        {PageGeneralTexts.END_SUBSCRIPTION}
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {modalType === ModalTypeEnum.DATA_FILE_CONFIRMATION ? (
                        <DataFilePopUp profile={profile} toggle={handleClose} />
                    ) : (
                        ""
                    )}
                    {modalType === ModalTypeEnum.TERMINATION_CONFIRMATION ? (
                        <TerminationPopUp
                            profile={profile}
                            toggle={handleClose}
                        />
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>
        </>
    );
};
