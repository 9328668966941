import { PageGeneralTexts } from "commons/enums";
import { RegisterConfirmation } from "commons/types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Confirmation.scss";

export const Confirmation = ({ toggle }: RegisterConfirmation) => {
    const navigate = useNavigate();
    return (
        <div className="confirmation-container">
            <div className="bg-gradient">
                <div className="confirmation-wrapper">
                    <button
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <div className="close"></div>
                    </button>
                    <p>{PageGeneralTexts.CONFIRMATION_MESSAGE}</p>
                </div>
            </div>
        </div>
    );
};
