import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import React, { useState } from "react";
import "./Dropdown.scss";
import { FAQ } from "commons/types";
import parse from 'html-react-parser';

export const Dropdown = ({ question, answer }: FAQ) => {
    const [expand, setExpand] = useState(false);
    const changeDropdownIcon = () => {
        setExpand(!expand);
    };

    return (
        <div className="gradient-dropdown">
            <Accordion sx={{ width: "100%", margin: "auto", height: "98%" }}>
                <AccordionSummary
                    onClick={() => {
                        changeDropdownIcon();
                    }}
                    expandIcon={
                        expand ? (
                            <RemoveCircleOutlineIcon />
                        ) : (
                            <AddCircleOutlineIcon />
                        )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography
                        className={
                            expand ? "open-question-title" : "question-title"
                        }
                        textAlign="left"
                    >
                        {question}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className="answer-text p-margin-remove">{parse(answer || '<span></span>')}</Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
