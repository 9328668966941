export enum ToasterMessagesEnums {
    //#############################################LOGIN
    PHONE_NOT_RECOGNIZED = "Unknown phone number",
    SESSION_EXPIRED = "Your login session has expired. To continue, please log in again",
    //############################################ OTP
    OTP_SEND_SUCCESSFUL = "OTP has been sent successfully",
    OTP_SEND_FAILED = "OTP sent failed",
    OTP_IS_VALID = "OTP authentication successful",
    OTP_IS_FAILED = "OTP authentication failed",
    OTP_HAS_RESEND_SUCCESSFUL = "OTP has been resend successfully",
    OTP_HAS_RESEND_FAILED = "OTP resend has failed",
    //############################################ REGISTER
    REGISTER_SUCCESSFUL = "Registration request send successfully",
    REGISTER_FAILED = "Registration request has failed",
    //############################################ HEADER

    // ########################################### PROFILE
    FAILED_TO_GET_TRANSACTIONS = "Failed to get transactions",
    FAILED_TO_GET_PROFILE = "Failed to get profile",
    CSV_DOWNLOAD = "CSV is starting to download",
    FAILED_CSV_DOWNLOAD = "Something went wrong with downloading csv",

    // ######################################### Credit Guard
    // the payment process is what I call the process inside the iframe
    // the charging process is the actual charging which is the second phase
    PAYMENT_COMPLETED = "Payment process was completed successfully",
    PAYMENT_IN_MPI_FAILED = "Payment process was unsuccessful",
    FETCHING_PAYMENT_URI_FAILED = "Fetching the URI for payment page failed",
    CHARGING_PROCESS_SUCCEEDED = "The charging process was successful. Virtual machine creation will take a few minutes to complete",
    CHARGING_PROCESS_FAILED = "The charging process has failed",

    // ######################################### FAQ
    FAILED_TO_GET_FAQ = "Fetching FAQ failed",

    // ##################################### PAYMENT
    FAILED_TO_FETCH_RESEARCHER_DETAILS = "Failed to fetch researcher details",
    DETAILS_SAVED = "Checkout details saved successfully.",
    FAILED_TO_GET_PAYMENT_SETTINGS = "Fetching Payment Settings Failed",
}
