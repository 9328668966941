import {
    PageGeneralTexts,
    PageNavigation,
    PhonePrefixResponse,
    ToasterMessagesEnums,
} from "commons/enums";
import { AuthUser } from "Models/AuthUser.model";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { toast } from "react-toastify";
import { AuthService, ResearcherService } from "services";
import { Button, Select, Grid, Link, MenuItem, TextField, Typography } from "@mui/material";
import { PhonePrefix, PrefixesResponseType } from "commons/types";

export const Login = () => {
    const [prefix, setPrefix] = useState([] as PhonePrefix[]);
    const [phone, setPhone] = useState("");
    const [selectPrefix, setSelectPrefix] = useState("+972");
    const [toggleAreaOptions, setToggleAreaOptions] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getPrefixes();

        const sessionExpired = localStorage.getItem('sessionExpired');
        if (sessionExpired === '1') {
            toast.info(ToasterMessagesEnums.SESSION_EXPIRED);
            localStorage.removeItem('sessionExpired');
        }
    }, []);

    const validatePhone = async () => {
        try {
            const result = await AuthService.getInstance().validatePhone({
                phone: encodeURIComponent(selectPrefix+phone),
            } as AuthUser);
            if (result) {
                toast.success(ToasterMessagesEnums.OTP_SEND_SUCCESSFUL);
                return navigate("/otp", { replace: true });
            } else {
                toast.error(ToasterMessagesEnums.OTP_SEND_FAILED);
            }
        } catch (error) {
            toast.error(ToasterMessagesEnums.OTP_SEND_FAILED);
        }
    };

    const getPrefixes = async () => {
        const result: PrefixesResponseType | string =
            await ResearcherService.getInstance().getPrefixes();
        if (
            typeof result === "object" &&
            PhonePrefixResponse.RESPONSE in result
        ) {
            setPrefix(result.prefix);
        }
    };

    const handlePrefix = (prefix: string) => {
        console.log(prefix);

        setSelectPrefix(prefix);
    };

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: "10%",
            },
        },
    };

    const validatePhoneBeforeOTP = async () => {
        try {
            const result =
                await AuthService.getInstance().validatePhoneBeforeOTP({
                    phone: encodeURIComponent(selectPrefix+phone),
                } as AuthUser);
            if (result) {
                validatePhone();
            } else {
                toast.error(ToasterMessagesEnums.PHONE_NOT_RECOGNIZED);
            }
        } catch (error) {
            toast.error(ToasterMessagesEnums.PHONE_NOT_RECOGNIZED);
        }
    };

    return (
        <>
            <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                xs={12}
                sx={{
                    backgroundImage: 'url("images/login_bg.svg")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
            >
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    xs={11}
                    md={6}
                    className="login"
                    margin={"auto"}
                    marginY={20}
                >
                    <Grid
                        container
                        display={"flex"}
                        direction={"column"}
                        paddingX={"20%"}
                        paddingY={"12%"}
                        xs={11.99}
                        sx={{ backgroundColor: "#ebf7ff", zIndex: 1 }}
                        className="phone_login"
                    >
                            <Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="login_title"
                                >
                                    <Typography
                                        variant="h1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#2457A0"}
                                        textAlign={"left"}
                                        fontWeight={500}
                                        width={"100%"}
                                        lineHeight={"35px"}
                                    >
                                        {PageGeneralTexts.LOGIN_TO_ACCOUNT_A}
                                    </Typography>

                                    <Typography
                                        variant="h1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#2457A0"}
                                        textAlign={"left"}
                                        fontWeight={500}
                                        width={"100%"}
                                        lineHeight={"35px"}
                                        marginBottom={7}
                                    >
                                        {PageGeneralTexts.LOGIN_TO_ACCOUNT_B}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    className="login_title_mobile"
                                >
                                    <Typography
                                        variant="h1"
                                        fontSize={"24px"}
                                        margin={0}
                                        color={"#2457A0"}
                                        textAlign={"left"}
                                        fontWeight={500}
                                        width={"100%"}
                                        lineHeight={"35px"}
                                    >
                                        {PageGeneralTexts.LOGIN_TO_ACCOUNT_A + ' ' + PageGeneralTexts.LOGIN_TO_ACCOUNT_B}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontSize={"20px"}
                                        margin={0}
                                        marginX={0}
                                        marginBottom={1}
                                        color={"#666"}
                                        textAlign={"left"}
                                        fontWeight={500}
                                    >
                                        {PageGeneralTexts.MOBILE_NUMBER}
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid
                                            item
                                            xs={4}
                                            display={"flex"}
                                            direction={"column"}
                                            className="prefix"
                                        >
                                        <Select
                                            value={selectPrefix}
                                            sx={{
                                                width: "100%",
                                                height: "55px",
                                                backgroundColor: "#f1f9fe",
                                                border: "2px solid #666",
                                            }}
                                            id="prefix"
                                            MenuProps={MenuProps}
                                            displayEmpty
                                        >
                                            <MenuItem value="">
                                                <Typography
                                                    variant="body1"
                                                    fontSize={"18px"}
                                                    margin={0}
                                                    color={"rgba(17, 17, 19, 0.40)"}
                                                    fontWeight={500}
                                                    textAlign={"left"}
                                                >
                                                    {"Area"}
                                                </Typography>
                                            </MenuItem>
                                            {prefix.map((fix, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={fix.dial_code}
                                                        className="options"
                                                        onClick={() => {
                                                            handlePrefix(
                                                                fix.dial_code
                                                            );
                                                            setToggleAreaOptions(
                                                                !toggleAreaOptions
                                                            );
                                                        }}
                                                    >
                                                        {fix.name +
                                                            " " +
                                                            fix.dial_code}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            display={"flex"}
                                            direction={"column"}
                                            className="prefix"
                                        >
                                            <TextField
                                                variant="outlined"
                                                placeholder="Enter your mobile number"
                                                id="phone"
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setPhone(event.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    xs={12}
                                    sx={{
                                        marginTop: 10,
                                        display: "flex",
                                        justifyContent: "start",
                                    }}
                                    className="button_container"
                                >
                                    <Grid item marginBottom={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#2457A0",
                                                paddingX: "57px",
                                                paddingY: "9px",
                                                fontSize: "20px",
                                                color: "white",
                                                borderRadius: "45px",
                                                textTransform: "none",
                                                fontWeight: 400,
                                                marginRight: 3,
                                            }}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                validatePhoneBeforeOTP();
                                            }}
                                        >
                                            {PageGeneralTexts.LOGIN}
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            variant="body1"
                                            fontSize={"20px"}
                                            margin={0}
                                            marginX={0}
                                            marginBottom={0}
                                            color={"#666"}
                                            textAlign={"left"}
                                            fontWeight={500}
                                        >
                                            {PageGeneralTexts.NEW_USER}
                                        </Typography>

                                        <Link
                                            sx={{
                                                marginLeft: 1,
                                                fontSize: "20px",
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    PageNavigation.REGISTER
                                                );
                                            }}
                                        >
                                            {PageGeneralTexts.REGISTER}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
