import './App.css';
import { Router } from './Router';
import { SettingsService } from "services/api/SettingsService.service";

await SettingsService.getInstance().getSiteSettings();

function App() {
  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;
