import axios from "axios";
import { RequestEnum, Routes, StorageKeys } from "commons/enums";
import { environment } from "environments/Environment";
import { AuthService, LocalStorageService } from "services";

export class VirtualMachineService {
    private static instance: VirtualMachineService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new VirtualMachineService();
        }
        return this.instance;
    }

    async getExportData(): Promise<string> {
        const response = await axios.request<string>({
            method: RequestEnum.Get,
            url: `${environment.API_URL}${Routes.GET_VM_EXPORT}`,
            headers: AuthService.getInstance().getByHeaderToken(
                StorageKeys.AccessToken
            ),
        });
        return response.data;
    }
}
