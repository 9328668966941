import { ErrorResponse } from "@remix-run/router";
import { RequestEnum, Routes } from "commons/enums";
import { ContactUsResponseType } from "commons/types";
import { ContactUs } from "Models/ContactUs.model";
import { LocalStorageService } from "services";
import GlobalApiService from "./GlobalApi.service";

export class ContactUsService {
    private static instance: ContactUsService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new ContactUsService();
        }
        return this.instance;
    }

    async sendMessage(
        message: ContactUs
    ): Promise<ContactUsResponseType | string> {
        const newMessage: ContactUsResponseType | ErrorResponse | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Post,
                Routes.POST_NEW_MESSAGE,
                message
            );

        let res = {} as ContactUsResponseType | string;

        if (typeof newMessage === "object" && "message" in newMessage) {
            res = newMessage;
        }
        if (typeof newMessage === "string") {
            res = newMessage;
        }

        return res;
    }
}
