import { Footer } from "components/footer/Footer";
import { Header } from "components/header/Header";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthGuardService } from "services/guard";

const OTPRouteGuard = () => {
    const hasOTP =
        AuthGuardService.getInstance().isResearcherOTPAuthenticated();
    const loggedIn = AuthGuardService.getInstance().isResearcherLoggedIn();
    if (hasOTP && !loggedIn) {
        return (
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        );
    }
    if (!hasOTP && loggedIn) {
        return <Navigate to="/"></Navigate>; //  TODO:change to home when there is a home page
    }

    return <Navigate to="/login"></Navigate>;
};

export default OTPRouteGuard;
