import { DataFileConfirmation, DataFileResponseType } from "commons/types";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { ResearcherService } from "services/api/ResearcherService.service";
import "./DataFilePopUp.scss";
import { Button, CardMedia, Grid, Tab, Typography } from "@mui/material";
import { PageGeneralTexts } from "commons/enums";

export const DataFilePopUp = ({ profile, toggle }: DataFileConfirmation) => {
    const [toggleSuccess, setToggleSuccess] = useState(false);

    const getDataFile = async () => {
        const result: DataFileResponseType | string =
            await ResearcherService.getInstance().getDataFile(profile._id);
        if (typeof result === "object" && "message" in result) {
            toast.success(result.message);
            setToggleSuccess(true);
        } else if (typeof result === "string") {
            toast.error(result);
        }
    };

    return (
        <Grid
            container
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "100%" }}
        >
            <Grid xs={6} md={5} container className="dataFile-container">
                <Grid
                    container
                    display={"flex"}
                    direction={"column"}
                    paddingTop={"8%"}
                    paddingBottom={"6%"}
                    paddingX={"10%"}
                    xs={11.99}
                    sx={{
                        backgroundColor: "#EBF7FF",
                        zIndex: 1,
                        borderRadius: "5px",
                        position: "relative",
                    }}
                >
                    {!toggleSuccess ? (
                        <Grid container>
                            <Grid item marginBottom={7} className="notice_grid">
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    marginX={0}
                                    marginBottom={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    fontWeight={300}
                                    className="dt_notice"
                                >
                                    {PageGeneralTexts.DATA_FILE_NOTICE}
                                </Typography>
                            </Grid>
                            <Grid className="action_grid">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#2457A0",
                                        paddingX: "57px",
                                        paddingY: "9px",
                                        fontSize: "20px",
                                        color: "white",
                                        borderRadius: "45px",
                                        border: "2px solid #2457A0",
                                        textTransform: "none",
                                        fontWeight: 400,
                                        marginRight: 3,
                                    }}
                                    onClick={getDataFile}
                                    className="action_buttons"
                                >
                                    {PageGeneralTexts.CONFIRM}
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "transparent",
                                        paddingX: "57px",
                                        paddingY: "9px",
                                        fontSize: "20px",
                                        color: "#2457A0",
                                        borderRadius: "45px",
                                        border: "2px solid #2457A0",
                                        textTransform: "none",
                                        fontWeight: 400,
                                        marginRight: 3,
                                    }}
                                    onClick={toggle}
                                    className="action_buttons_cancel"
                                >
                                    {PageGeneralTexts.CANCEL}
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item>
                            <Button
                                onClick={toggle}
                                sx={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    minHeight: "35px",
                                    minWidth: "35px",
                                    backgroundColor: "transparent",
                                    border: "0",
                                    width: "36px",
                                }}
                            >
                                <CardMedia
                                    sx={{
                                        backgroundColor: "transparent",
                                        width: "36px",
                                    }}
                                    component="img"
                                    height="auto"
                                    image="images/closecircle.svg"
                                    alt="banner"
                                />
                            </Button>
                            <Typography
                                variant="body1"
                                fontSize={"24px"}
                                margin={0}
                                marginX={0}
                                marginBottom={0}
                                color={"#2457A0"}
                                textAlign={"left"}
                                fontWeight={500}
                            >
                                {PageGeneralTexts.DATA_FILE_LOCATION}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
