import { ChargeResponseType, PaymentPageResponseType } from "commons/types";
import GlobalApiService from "./GlobalApi.service";
import { RequestEnum, Routes } from "commons/enums";
import { ChargeResearcherRequest, PaymentPageData } from "commons/interfaces";

export class PaymentService {
    private static instance: PaymentService;
    static getInstance() {
        if (!this.instance) {
            this.instance = new PaymentService();
        }
        return this.instance;
    }

    async getPaymentPage(paymentPageData: PaymentPageData) {
        const result: PaymentPageResponseType | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Get,
                `${Routes.GET_PAYMENT_PAGE}?sum=${paymentPageData.sum}`
            );
        let res = {} as PaymentPageResponseType | string;
        if (typeof result === "object" && "URI" in result) {
            res = result.URI;
            return res;
        }
        throw new Error("An error occurred while fetching the URI of the MPI");
    }

    async chargeResearcher(chargeRequestData: ChargeResearcherRequest) {
        const result: ChargeResponseType | string =
            await GlobalApiService.generalRequest(
                RequestEnum.Post,
                `${Routes.POST_CHARGE_REQUEST}`,
                chargeRequestData
            );
        let res = {} as PaymentPageResponseType | string;
        if (typeof result === "object" && "chargeMessage" in result) {
            res = result.chargeMessage;
            return res;
        }
        throw new Error(
            "An error occurred while performing charging operation."
        );
    }
}
