import React, { useEffect, useState } from "react";
import "./Purchase.scss";
import { SiteSettings } from "commons/types";
import { LocalStorageService } from "services";
import { PurchaseCard } from "components/purchase-card/PurchaseCard";
import { PageGeneralTexts } from "commons/enums";
import { useNavigate } from "react-router-dom";
import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import parse from 'html-react-parser';

export const Purchase = () => {
    const [settings, setSettings] = useState<SiteSettings>({} as SiteSettings);

    const getSiteSettings = () => {
        const local = new LocalStorageService();
        const settingsString: string =
            local.getItemFromLocalStorage("smd") || "";
        if (settingsString !== "") {
            const siteSettings: SiteSettings = JSON.parse(settingsString);
            setSettings(siteSettings);
        }
    };

    useEffect(() => {
        getSiteSettings();
    }, []);

    const navigate = useNavigate();

    return (
        <>
            <Grid
                container
                xs={12}
                sx={{ backgroundColor: "#F9FBFC" }}
                className="register-page"
            >
                <Grid item xs={12}>
                    <Box sx={{ backgroundColor: "#f1f9fe" }}>
                        <img
                            style={{ width: "100%", backgroundSize: "cover" }}
                            src="images/home-top-banner.svg"
                            alt="banner"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ backgroundColor: "#f1f9fe" }}>
                    <Typography
                        variant="h1"
                        fontSize={"70px"}
                        marginX={"10%"}
                        marginTop={10}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"fit-content"}
                        fontWeight={700}
                        className="main_title"
                    >
                        {PageGeneralTexts.INSTALLATION_METHOD}
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize={"20px"}
                        marginX={"10%"}
                        marginTop={5}
                        color={"#2457a0"}
                        textAlign={"left"}
                        width={"60%"}
                        fontWeight={400}
                        className="sub_title p-margin-remove"
                    ><br/>
                        {parse(settings.payment_instructions || '<span></span>')}
                    </Typography>
                    <CardMedia
                        sx={{ backgroundColor: "#F9FBFC" }}
                        component="img"
                        height="auto"
                        image="images/purchase-curve.svg"
                        alt="banner"
                    />
                </Grid>
                <Grid
                    container
                    display={"flex"}
                    paddingTop={5}
                    sx={{ backgroundColor: "#F9FBFC" }}
                    justifyContent={"space-between"}
                    xs={8}
                    margin={"auto"}
                    marginTop={5}
                    className="cards-wrapper"
                >
                    <Grid item xs={12} md={5} lg={2.7}>
                        <PurchaseCard
                            title="Operating System"
                            imgUrl="images/windows-icon.svg"
                            imgHeight={53}
                            bottom_title="Windows 11"
                            bottom_sub_title=""
                        />
                    </Grid>
                    <Grid item xs={12} md={5} lg={2.7}>
                        <PurchaseCard
                            title="Cores"
                            imgUrl="images/cpu-icon.svg"
                            imgHeight={81}
                            bottom_title="4"
                            bottom_sub_title=""
                        />
                    </Grid>
                    <Grid item xs={12} md={5} lg={2.7}>
                        <PurchaseCard
                            title="RAM"
                            imgUrl="images/gpu-icon.svg"
                            imgHeight={42}
                            bottom_title="16 GiB"
                            bottom_sub_title=""
                        />
                    </Grid>
                    <Grid item xs={12} md={5} lg={2.7}>
                        <PurchaseCard
                            title="Storage"
                            imgUrl="images/energy-icon.svg"
                            imgHeight={76}
                            bottom_title="127 GiB"
                            bottom_sub_title=""
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "auto",
                        marginBottom: "120px",
                    }}
                >
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            backgroundColor: "#2457A0",
                            paddingX: "55px",
                            paddingY: "22px",
                            fontSize: "24px",
                            color: "white",
                            borderRadius: "45px",
                        }}
                        onClick={() => {
                            navigate("/payment", { replace: true });
                        }}
                    >
                        {PageGeneralTexts.PAYMENT}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
