export enum PageGeneralTexts {
    // ################################ LOGIN PAGE
    LOGIN_TO_ACCOUNT_A = "Login to your account with your ",
    LOGIN_TO_ACCOUNT_B = "mobile number",
    MOBILE_NUMBER = "Mobile Number",
    LOGIN = "Login",
    NEW_USER = "New user ?",
    REGISTER = "Register here",
    PASSWORD_WILL_BE_SEND = "The password will be sent to",
    CLICK_HERE = "click here",
    CHANGE_NUMBER = "to change the phone number",
    INVALID_PHONE = "Sorry, this number is not valid!",

    // ################################ OTP
    ENTER_OTP = "Enter the One Time Password",
    INVALID_OTP = "Invalid OTP, please try again",
    DID_NOT_RECEIVE_OTP = "I didn't get the SMS, RESEND OTP",
    SEND = "Send",

    // ########################### REGISTER
    REGISTRATION_TITLE = "Registration Request",
    GENERAL_STAR = "*",
    FIRST_NAME = "First Name",
    LAST_NAME = "Last Name",
    ID = "ID",
    ID_PASSPORT = "ID Number (National or Passport)",
    BUSINESS_ID = "Business ID",
    PHONE_NUMBER = "Phone Number",
    COUNTY_CODE = "Country code",
    EMAIL = "Email",
    ORGANIZATION = "Institution Name",
    REQUEST = "Your request",
    THE_DATA = "\"The Data\"",
    TERMS_CONDITIONS_ONE = "I confirm that I have read ",
    TERMS_CONDITIONS_TWO = "terms and conditions ",
    TERMS_CONDITIONS_THREE = " page and understood the size, variety and structure of the data, as well as the advantages and limitations associated with synthetic data, and found it well suited to my study needs.",
    INVALID_FIELD = "One or more fields are empty",
    TERMS_CONDITIONS_FOUR = "I hereby acknowledge that I have read, understood and I accept the ",
    TERMS_CONDITIONS_FIVE = `of the "Pay & Research" platform. By accessing and using this platform, I agree to abide by the rules and guidelines set forth in the `,
    TERMS_CONDITIONS_SIX = "document. I understand that it is my responsibility to review and familiarize myself with these terms, as they govern the use of the platform and its services.",

    // ############## PAYMENT
    CARD_NUMBER = "Card Number",
    EXPIRY = "Expiry",

    // ############## FORM SUBMIT
    SAVE = "Save",

    // ############## VIRTUAL LAB ROOM
    TOTAL = "Total",
    INITIAL_PAYMENT = "Initial Payment",
    MONTHLY_PAYMENT = "Monthly Recurring Payments",
    PAYMENT_DUE_DATE = "Payment Due Date",
    PAY = "Pay",
    CHECKOUT = "Checkout",
    PAY_WITH_CREDITCARD = "Pay With Credit Card",
    TERMS_AND_CONDITIONS = "Terms & Conditions",

    // ################################ CONFIRMATION
    CONFIRMATION_MESSAGE = "Your request has been successfully sent, our representatives will contact you to schedule a video call as soon as possible",

    // ################################ HEADER
    REGISTER_HEADER = "Register",
    LOGOUT_HEADER = "Logout",
    LOGIN_HEADER = "Login",
    PROFILE = "Profile",

    // ################################### FOOTER
    DESCRIPTION = "Leumit Start is Leumit Health Services innovation and digital health unit. We aim to encourage Innovation in the HealthTech industry by supplying responsible and reasonable access to de-identified high quality clinical data, clinical consultancy, clinical trials and more. Leumit Start operates under Leumit La'atid Ltd.",
    HOME_LINK = "Home Page",
    TERMS_LINK = "Terms & Conditions",
    CONTACT_LINK = "Contact Us",
    FAQ_LINK = "FAQ",
    DISCLAIMER_LINK = "The Data",
    PAY_RESEARCH = "Pay & Research",

    // ############################# PROFILE
    VM_DATA_FILE = "Virtual Lab Room Installation Completed",
    GET_DATA_FILE = "Get Data File",
    ACCESS_MACHINE = "Access to Virtual Lab Room",
    P81_LOGIN = "https://leumitstart.perimeter81.com/",
    END_SUBSCRIPTION = "End Subscription",
    VIRTUAL_LAB_ROOM = "Virtual Lab Room",
    TYPE = "Type",
    NAME = "Name",
    SIZE = "Size",
    VIRTUAL_LAB_ROOM_EXPORT = "Virtual_Lab_Room",
    PAYMENT_HISTORY = "Payment History",
    DATE = "Date",
    SUBTOTAL = "Subtotal",
    TRANSACTIONS_TYPE = "Transaction Type",
    STATUS = "Status",
    INVOICE = "Invoice",
    TRAN_ID = "Transaction id",
    TRANSACTIONS = "Transactions",
    DATA_FILE_NOTICE = "Please note, after confirming the completion of the installation, it will not be possible to make changes in the Virtual Lab Room. Are you sure you want to continue?",
    CONFIRM = "Confirm",
    CANCEL = "Cancel",
    DATA_FILE_LOCATION = "The data file has been successfully installed on your machine and is located at Disk (F:)",
    TERMINATION_NOTICE = "Ending subscription will delete your virtual machine, are you sure you want to proceed?",
    TERMINATION_FILES_LOCATION = "Please transfer the relevant files to the 'To Export' folder and we will make sure to submit them to you",
    RATE_US = "How would you rate us?",
    SUBMIT = "Submit",
    // ########################################### CONTACT US
    REQUEST_SUBJECT = "Request Subject",
    CONTACT_US = "Contact Us",
    YOUR_REQUEST = "Your Request",
    REGISTER_REQUEST = "Please describe the subject of your study (we'll not use it but to understand the need for data)",
    MESSAGE_SEND = "Your request has been successfully sent, our representatives will contact you soon",
    // ########################################### FAQ
    FAQ = "Frequently Asked Questions",
    question1 = "title question 1",
    question2 = "title question 2",
    // ########################################### PURCHASE
    PAYMENT = "Payment",
    INSTALLATION_METHOD = "Installation method - Standard Machine",
    DIFFERENT_MACHINE = "In case you are interested in purchasing a different type of VM, please contact us",
    // ########################################## HOME
    STEP_ONE = "01",
    STEP_ONE_TEXT = "Register",
    STEP_TWO = "02",
    STEP_TWO_TEXT = "Sign & Pay",
    STEP_THREE = "03",
    STEP_THREE_TEXT = "Get Access",
    THANK_YOU = "Thank you for paying",
    SOMETHING_WENT_WRONG = "sorry something went wrong",
}
