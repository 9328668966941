import "./Loader.scss";
import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { LoaderType } from "commons/types";

export const Loader = ({ loaderState }: LoaderType) => {
    if (loaderState) {
        return (
            <div className="loader-wrapper">
                <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                </Box>
            </div>
        );
    } else {
        return null;
    }
};
