import { PageGeneralTexts } from "commons/enums";
import { GeneralToggleProp } from "commons/types";
import React from "react";
import "./MessageSend.scss";

export const MessageSend = ({ toggle }: GeneralToggleProp) => {
    return (
        <div className="message-send-container">
            <div className="custom-border">
                <div className="contact-us">
                    <button className="close" onClick={toggle}>
                        <img src="images/close.png" alt="close" />
                    </button>
                    <p>{PageGeneralTexts.MESSAGE_SEND}</p>
                </div>
            </div>
        </div>
    );
};
