import React, { useEffect } from "react";
import "./Disclaimer.scss";
import { Box, Grid, Hidden, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import parse from 'html-react-parser';  

export const Disclaimer = () => {

const tableData = [
    { diagnosis: 'Colorectal cancer', cohortSize: 561 },
    { diagnosis: 'Lung cancer', cohortSize: 909 },
    { diagnosis: 'Breast cancer', cohortSize: 1075 },
    { diagnosis: 'Skin cancer', cohortSize: 2636 },
    { diagnosis: 'Diabetes', cohortSize: 10236 },
    { diagnosis: 'CKD', cohortSize: 2052 },
    { diagnosis: 'COPD', cohortSize: 3488 },
    { diagnosis: 'Asthma', cohortSize: 29258 },
    { diagnosis: 'Stroke', cohortSize: 1817 },
    { diagnosis: 'Dementia syndromes', cohortSize: 2146 },
    { diagnosis: 'NAFLD', cohortSize: 2784 },
    { diagnosis: 'Cardiovascular', cohortSize: 10092 }
  ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Grid className="theData" sx={{ backgroundColor: "#f1f9fe" }}>
            <Grid item xs={12}>
                <Box>
                    <img
                        style={{ width: "100%", backgroundSize: "cover" }}
                        src="images/main-banner.png"
                        alt="banner"
                    />
                </Box>
            </Grid>
            <Grid
                container
                xs={10}
                md={11}
                sx={{
                    backgroundColor: "#f1f9fe",
                    margin: "auto",
                    paddingBottom: 20,
                }}
            >
                <Grid item container>
                    <Typography
                        variant="h1"
                        fontSize={"70px"}
                        margin={0}
                        color={"#2457a0"}
                        textAlign={"left"}
                        fontWeight={700}
                        className="data-title"
                    >
                        About the data set
                    </Typography>
                    <Grid
                        container
                        xs={11}
                        display={"flex"}
                        alignItems={"flex-start"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{marginTop: {xs: 2, md: 10}}}
                        paddingBottom={0}
                    >
                <Grid item xs={12} lg={6}>
                <Typography
                        variant="body1"
                        fontSize={"24px"}
                        color={"#2457a0"}
                    >
                        The data intended for sharing is a "one size fits all"
                        synthetic data, based on real-world, raw data from Leumit's
                        EMR. <br/><br/>The synthetic data represents patients' information
                        from 2003 until June 2018 but due to the synthesis
                        process the data will be spread beyond this range (for
                        computational reasons only). <br/>
                        <Hidden lgUp>
                            <Grid
                            item
                            xs={12}
                            lg={5}
                            sx={{marginLeft: {xs: 1}, flexShrink: 0, marginTop: {xs:3, lg: 0}}}
                            className="media-grid"
                        >

                                <div className="png-container">
                                        <img src="/images/main_image.png" alt="img" style={{maxWidth: '100%'}} />
                                    </div>
                            </Grid>
                        </Hidden>
                        <br/>The data contains
                        information on approximately 130,000 synthetic patients
                        in a distribution close to reality in Leumit. The data
                        however, does not claim to be a full representation of
                        the population. For a more in-depth explanation, please refer to the attached file below. <br/>
                        <br/>This cohort is randomly selected and is
                        a representative sample of the population in terms of
                        demographic, medical condition and other aspects. The
                        synthetic data is structured in such a way that it
                        simulates the statistical behavior of the real
                        population and enables a reliable analysis and a very
                        close approximation to the real-world data. <br/>
                </Typography>
                </Grid>
                <Hidden lgDown>
                    <Grid
                    item
                    xs={12}
                    lg={5}
                    sx={{marginLeft: {xs: 1}, flexShrink: 0, marginTop: {xs:3, lg: 0}}}
                    className="media-grid"
                >

                    <div className="png-container">
                            <img src="/images/main_image.png" alt="img" style={{maxWidth: '100%'}} />
                        </div>
                </Grid>
                </Hidden>
                </Grid>
                    <Typography
                        variant="body1"
                        fontSize={"24px"}
                        color={"#2457a0"}
                    >
                        <br/>The data
                        will be accessible in CSV format files and contains the
                        following types of information:
                        <ul>
                            <li>
                                Customer Details: Gender, demographics, customer
                                status, etc.
                            </li>
                            <li>
                                Provider’s Treatments: Data regarding
                                hospitalizations, ERs, and other treatments
                                (treatment code, date, etc.) as reported by
                                external providers.
                            </li>
                            <li>
                                Physician’s/Nurse’s Procedures: Procedures
                                performed by the Physicians/ nurses in the HMO
                                clinics (Procedure code, date, etc.).
                            </li>
                            <li>
                                Medication Consumption: Data regarding
                                medication consumption (Date, Drug ATC7 code,
                                Quantity, etc.).
                            </li>
                            <li>
                                Medication Prescriptions: Data regarding
                                prescriptions for medications given by the
                                Physician (Date, Drug ATC7 code, Dose, etc.).
                            </li>
                            <li>
                                Physicians' Details: License number
                                (deidentified token), specialty.
                            </li>
                            <li>
                                HMO Clinic Visits: Physician license
                                (deidentified token), Customer token, date &
                                time.
                            </li>
                            <li>
                                Diagnosis - HMO: Diagnosis given by the HMO’s
                                physicians encoded by ICD9 code (Physician
                                license deidentified token), Customer token,
                                date & time, ICD9, status, etc.).
                            </li>
                            <li>
                                Diagnosis – Hospitals: Diagnosis given by the
                                hospital’s physicians encoded by ICD9 code
                                (Hospital, Date, ICD9) (data from 2007,
                                gradually added).
                            </li>
                            <li>
                                Medical Measures: Height, weight, heartbeat,
                                blood pressure, etc.
                            </li>
                            <li>
                            Sensitivities: Medications, allergies, food, etc. (Date, type, description, status, etc.).
                            </li>
                            <li>
                                Laboratory Tests: Demands, Orders, Results
                                (Date, type, name, Physician license
                                (deidentified token), results, etc.).
                            </li>
                            <li>
                                Medical Assessments: Data regarding estimations/
                                Assessments performed mainly by nurses (Date,
                                assessment code, Nurse details, result, question
                                & answer code, etc.
                            </li>
                            <li>
                                Risk Factors: Such as smoking, drinking, drugs,
                                etc. (risk code, date, value, etc.).
                            </li>
                            <li>
                                Insurance Details: Insurance type, date
                                start/end
                            </li>
                        </ul>
                    </Typography>
                    <Typography
                        variant="body1"
                        fontSize={"24px"}
                        color={"#2457a0"}
                    >
                        In the table below, we have gathered data on the prevalence of a few common clinical conditions in the dataset that are often subjects of research (based on documented diagnoses). For detailed information on these conditions and the diagnoses that were included, <a href="https://www.innovation.leumit.co.il/_files/ugd/6ee5cd_04a1402da6e949bdb6e3a6d99cad8164.xlsx?dn=Diagnosis%20cohort%20size.xlsx" target="_blank">click here</a>.
                    </Typography>
                    <Typography variant="body1" color={"#2457a0"}>
                        <TableContainer
                            component={Paper}
                            sx={{
                                backgroundColor: "#F1F9FE",
                                maxWidth: "500px",
                                boxShadow: "none",
                                borderRadius: "5px",
                                paddingTop: "15px",
                                paddingBottom: "65px",
                                position: "relative",
                            }}
                        >
                            <Table aria-label="cohort table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className="th_cell"
                                            align="center"
                                            sx={{
                                                fontSize: "32px",
                                                fontWeight: "700",
                                                color: "#2457A0",
                                                paddingY: "30px",
                                            }}
                                        >
                                            <strong>Diagnosis</strong>
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            align="center"
                                            sx={{
                                                fontSize: "32px",
                                                fontWeight: "700",
                                                color: "#2457A0",
                                                paddingY: "30px",
                                            }}
                                        >
                                            <strong>Cohort size</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": {
                                                    border: 0,
                                                },
                                            }}
                                        >
                                            <TableCell
                                                className="td_cell"
                                                align="center"
                                                sx={{
                                                    fontSize: "24px",
                                                    fontWeight: "400",
                                                    color: "#2457A0",
                                                }}
                                            >
                                                {row.diagnosis}
                                            </TableCell>
                                            <TableCell
                                                className="td_cell"
                                                align="center"
                                                sx={{
                                                    fontSize: "24px",
                                                    fontWeight: "400",
                                                    color: "#2457A0",
                                                }}
                                            >
                                                {row.cohortSize}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Typography>
                </Grid>
                    <Typography
                        variant="body1"
                        fontSize={"24px"}
                        color={"#2457a0"}
                    >
                        Limitation in the dataset:
                        <ul>
                            <li>
                                The data does not contain any prices and costs.
                            </li>
                            <li>
                                The data does not contain the names of locations
                                (towns, cities etc.), providers and hospitals.
                            </li>
                            <li>
                                Very rare occurrences are not fully reflected in
                                the synthetic data.
                            </li>
                            <li>
                                The synthetic data is not suitable for research
                                related to seasonality.
                            </li>
                            <li>
                                Medication names are based on the generic name and not the commercial name.
                            </li>
                        </ul>
                        For more information about the synthetic data, please read carefully these two files:<br/> 
                        <a href="/MDClone - Leumit Synthetic Data solution.pdf" target="_blank">
                        MDClone - Leumit Synthetic Data solution
                        </a><br/>
                        <a href="/Leumit synthetic datalake data documentation and specification 5.8.24.xlsx" target="_blank">Leumit-
                        The complete Data Catalog </a>
                    </Typography>
            </Grid>
        </Grid>
    );
};
