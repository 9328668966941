import AuthRouteGuard from "components/Guards/AuthRouteGuard";
import OTPRouteGuard from "components/Guards/OTPRouteGuard";
import { Otp } from "components/login/otp/Otp";
import { Profile } from "components/profile/Profile";
import { Register } from "components/register/Register";
import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Home } from "./components/home/Home";
import { Login } from "./components/login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Terms } from "components/terms/Terms";
import { Disclaimer } from "components/disclaimer/Disclaimer";
import { FAQ } from "components/FAQ/FAQ";

import { ContactUs } from "components/contactUs/ContactUs";
import GuestRouteGuard from "components/Guards/GuestRoutesGuard";
import { Payment } from "components/payment/Payment";
import { Purchase } from "components/purchase/Purchase";
import { PaymentSuccess } from "components/payment/PaymentSuccess";

export const Router: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<GuestRouteGuard />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/contactUs" element={<ContactUs />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/theData" element={<Disclaimer />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/" element={<Home />} />
                    </Route>

                    <Route element={<OTPRouteGuard />}>
                        <Route path="/otp" element={<Otp />}></Route>
                    </Route>
                    <Route element={<AuthRouteGuard />}>
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/payment" element={<Payment />} />
                        <Route path="/payment-success" element={<PaymentSuccess />} />
                        <Route path="/purchase" element={<Purchase />} />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
};
