import React, { useEffect } from "react";
import "./Payment.scss";
import { Box, Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export const PaymentSuccess = () => {
    return (
        <Grid
            container
            xs={12}
            sx={{ backgroundColor: "#F1F9FE" }}
            className=""
        >
            <Box sx={{ width: "100%" }}>
                <img
                    src="images/home-top-banner.svg"
                    alt="banner"
                    style={{ width: "100%", backgroundSize: "cover" }}
                />
            </Box>
            
            <Grid item xs={12}>
                <Box>
                    <Typography
                        variant="h1"
                        fontSize={"50px"}
                        margin={"60px"}
                        color={"#24a057"}
                        textAlign={"center"}
                        fontWeight={600}
                        className="terms-title"
                    >
                        Your payment was received successfully!
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="h2"
                        fontSize={"30px"}
                        margin={"20px"}
                        color={"#24a057"}
                        textAlign={"center"}
                        fontWeight={600}
                    >
                        We'll start setting up your virtual lab room and will contact you soon to provide safe access.
                    </Typography>
                </Box>
                <Box margin={"90px"}>
                    <NavLink to="/">
                        <span className="header_container_secondary">
                            <button className="btn-bg log">
                                Home Page
                            </button>
                        </span>
                    </NavLink>
                </Box>
            </Grid>
        </Grid>
    );
}
