import React, { useEffect, useState } from "react";
import "./Home.scss";
import { LocalStorageService } from "services";
import { SiteSettings } from "commons/types";
import ReactPlayer from "react-player";
import { PageGeneralTexts } from "commons/enums";
import { Box, Container, Grid, Hidden, Typography } from "@mui/material";
import parse from 'html-react-parser';

export const Home = () => {
    const [settings, setSettings] = useState<SiteSettings>({} as SiteSettings);
    const [mediaType, setMediaType] = useState<boolean>(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getSiteSettings = () => {
        const local = new LocalStorageService();
        const settingsString: string =
            local.getItemFromLocalStorage("smd") || "";
        if (settingsString !== "") {
            const siteSettings: SiteSettings = JSON.parse(settingsString);
            setSettings(siteSettings);
            handleMediaType(siteSettings);
        }
    };

    const handleMediaType = (siteSettings: SiteSettings) => {
        if (
            (siteSettings && siteSettings?.src_url.includes(".mp4")) ||
            siteSettings?.src_url.includes("youtube")
        ) {
            setMediaType(true);
        }
    };

    const handleLink = (content: string) => {
        if (content && content.includes("https")) {
            const splited = content.split(" ");
            const fixed: any[] = [];
            splited.forEach((s: string) => {
                if (s.includes("https")) {
                    fixed.push(<a href={s}>{s}</a>);
                    return;
                }
                fixed.push(`${s} `);
            });

            return fixed;
        }
        return content;
    };

    useEffect(() => {
        getSiteSettings();
    }, []);

    return (
        <Grid
            className="home-page"
            container
            xs={12}
            display={"flex"}
            direction={"row"}
            justifyContent={"center"}
        >
            <Box sx={{ width: "100%" }}>
                <img
                    src="images/home-top-banner.svg"
                    alt="banner"
                    style={{ width: "100%", backgroundSize: "cover" }}
                />
            </Box>
            <Grid
                container
                xs={11}
                display={"flex"}
                alignItems={"center"}
                direction={"row"}
                justifyContent={"space-between"}
                sx={{marginTop: {xs: 2, md: 10}}}
                paddingBottom={0}
            >
                <Grid item sm={12} lg={6}>
                    <Typography
                        variant="h1"
                        color={"#2457A0"}
                        textAlign={"left"}
                        margin={0}
                        lineHeight={"75px"}
                        className="pay-research-title"
                    >
                        {settings.top_title}
                    </Typography>
                    <p className="pay-research-sub-title p-margin-remove">
                        <br/>
                        {parse(settings.top_title_content || '<span></span>')}
                    </p>
                </Grid>

                <Grid
                    item
                    sm={12}
                    lg={6}
                    minHeight={"350px"}
                    sx={{marginTop: {xs: 5, lg: 0}, marginBottom: {xs: 0, lg: 3}}}
                    className="media-grid"
                >
                    {mediaType ? (
                        <>
                        
                        <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'relative',
                                top: 0,
                                left: 0,
                                display: 'grid',
                                justifyItems: 'center',
                                marginTop: '50px',
                                height: '100%',
                            }}
                        >
                             <Hidden smUp>
                                <ReactPlayer
                                url={settings.src_url}
                                width="350px"
                                height="200px"
                                />
                            </Hidden>

                            <Hidden smDown>
                                <ReactPlayer
                                url={settings.src_url}
                                width="540px"
                                height="300px"
                                />
                            </Hidden>
                        </Box>
                        
                    </Box>
                    <p
                    style={{
                        padding: 0,
                        color: "#2457A0",
                        fontSize: "24px",
                        textAlign: "center",
                        fontWeight: "700",
                        marginTop: '5px'
                    }}
                    className="media-sub-title p-margin-remove"
                >
                    {parse(settings.src_content || '<span>Take a short Journey to experience our service</span>')}
                </p>
                        </>
                    ) : (
                        <div className="png-container">
                            <img src={settings.src_url} alt="img" style={{maxWidth: '580px'}} />
                            <p
                                style={{
                                    margin: 0,
                                    color: "#2457A0",
                                    fontSize: "24px",
                                    textAlign: "center",
                                    fontWeight: "700",
                                    marginTop: "10px",
                                }}
                                className="media-sub-title p-margin-remove"
                            >
                                {parse(settings.src_content || '<span></span>')}</p>
                        </div>
                    )}
                </Grid>
            </Grid>
            <Box sx={{ width: "100%" }} style={{ backgroundColor: "#EEF9F7" }}>
                <img
                    src="images/home-top-curve.svg"
                    alt="banner"
                    style={{ width: "100%", backgroundSize: "cover" }}
                />
            </Box>

            <Grid
                container
                xs={12}
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                paddingTop={10}
                paddingBottom={10}
                style={{ backgroundColor: "#EEF9F7" }}
            >
                <Grid
                    container
                    xs={12}
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"center"}
                >
                    <Grid item sm={11} md={5}>
                        <h2
                            style={{
                                textAlign: "left",
                                fontSize: "69px",
                                color: "#2457A0",
                                lineHeight: "75px",
                                margin: "0",
                            }}
                            className="changing_rules_title"
                        >
                            {settings.rule_title}
                        </h2>
                    </Grid>
                    <Grid item sm={11} md={5}>
                        <p
                            className="changing_rules_sub_title p-margin-remove"
                            style={{ fontSize: "24px", color: "#2457A0" }}
                        >
                            {parse(settings.rule_sub_title || '<span></span>')}
                        </p>
                    </Grid>
                </Grid>
                <Grid
                    xs={11}
                    container
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"space-between"}
                    paddingTop={20}
                    paddingBottom={5}
                    className="rules_container"
                >
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"baseline"}
                        alignSelf={"baseline"}
                        sm={11}
                        md={3}
                        className="rules"
                    >
                        <Grid
                            item
                            xs={12}
                            className="img-container"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "initial",
                            }}
                        >
                            <img
                                src="images/right-rule.svg"
                                className="img-rule-one"
                                alt="img"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p className="rule-title">
                                {handleLink(settings.rule_one)}
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p className="rule-content p-margin-remove">
                                {parse(settings.rule_one_content || '<span></span>')}
                            </p>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"baseline"}
                        alignSelf={"baseline"}
                        sm={11}
                        md={3}
                        className="rules"
                    >
                        <Grid
                            item
                            xs={12}
                            className="img-container"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "initial",
                            }}
                        >
                            <img
                                src="images/middle-rule.svg"
                                className="img-rule-two"
                                alt="img"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p className="rule-title">
                                {handleLink(settings.rule_two)}
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p className="rule-content p-margin-remove">
                                {parse(settings.rule_two_content || '<span></span>')}
                            </p>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"baseline"}
                        alignSelf={"baseline"}
                        sm={11}
                        md={3}
                        className="rules last-rule"
                    >
                        <Grid
                            item
                            xs={12}
                            className="img-container"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "initial",
                            }}
                        >
                            <img
                                src="images/left-rule.svg"
                                className="img-rule-three"
                                alt="img"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p className="rule-title">
                                {handleLink(settings.rule_three)}
                            </p>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <p className="rule-content p-margin-remove">
                                {parse(settings.rule_three_content || '<span></span>')}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    xs={11}
                    mt={3}
                    container
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"center"}
                >   
                    <a className="header_container_secondary" href="/register">
                        <button className="btn-bg log">
                            Click here to register
                        </button>
                    </a>
                </Grid>
            </Grid>

            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#EEF9F7",
                }}
            >
                <img
                    src="images/steps-banner.svg"
                    alt="banner"
                    style={{ width: "100%", backgroundSize: "cover" }}
                />
            </Box>

            <Grid
                container
                xs={12}
                justifyContent={"center"}
                style={{ backgroundColor: "#FFF" }}
            >
                <Grid
                    container
                    xs={11}
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"center"}
                    marginTop={0}
                    paddingBottom={10}
                >
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        xs={11}
                        className="step-grid"
                    >
                        <Grid
                            item
                            sm={11}
                            md={6}
                            style={{ display: "flex", alignItems: "center" }}
                            className="step-content-grid"
                        >
                            <p className="gradient-text">
                                {PageGeneralTexts.STEP_ONE}
                            </p>
                            <p className="sub-title">
                                {PageGeneralTexts.STEP_ONE_TEXT}
                            </p>
                        </Grid>
                        <Grid item sm={11} md={6}>
                            <p className="content p-margin-remove">
                                {parse(settings.step_one || '<span></span>')}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        xs={11}
                        className="step-grid"
                    >
                        <Grid
                            item
                            sm={11}
                            md={6}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <p className="gradient-text">
                                {PageGeneralTexts.STEP_TWO}
                            </p>
                            <p className="sub-title">
                                {PageGeneralTexts.STEP_TWO_TEXT}
                            </p>
                        </Grid>
                        <Grid item sm={11} md={6}>
                            <p className="content p-margin-remove">
                                {parse(settings.step_two || '<span></span>')}
                            </p>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        xs={11}
                        className="step-grid"
                    >
                        <Grid
                            item
                            sm={11}
                            md={6}
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <p className="gradient-text">
                                {PageGeneralTexts.STEP_THREE}
                            </p>
                            <p className="sub-title">
                                {PageGeneralTexts.STEP_THREE_TEXT}
                            </p>
                        </Grid>
                        <Grid item sm={11} md={6}>
                            <p className="content p-margin-remove">
                                {parse(settings.step_three || '<span></span>')}
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
