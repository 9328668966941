export enum PaymentEnums {

    //Placeholders
    FIRST_NAME = "First Name",
    LAST_NAME="Last Name",
    PHONE = "Phone",
    EMAIL = "Email",
    CARD_NUMBER = "Card Number",

    //TYPE
    SUBMIT = "submit",
    TEXT = "text",
    CELL = "cell",
    EMAIL_TYPE="email",

    //IDS
    FIRST_NAME_ID = "firstName",
    LAST_NAME_ID = "lastName",
    PHONE_ID = "phone",
    EMAIL_ID = "email",
    SELECT_ID = "select",
    CARD_NUMBER_ID = "card-number",

    //LABELS
    YEAR = "Year",
    MONTH = "Mon",
    DAY = "Day"
}
