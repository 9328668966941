export class AuthTokens {
    accessToken: string;
    refreshToken: string;
    OTPToken:string;
    constructor() {
        this.accessToken = "";
        this.refreshToken = "";
        this.OTPToken = "";
    }
}
