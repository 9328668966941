/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Button, Grid, Link, Tab } from "@mui/material";
import { PageGeneralTexts, PageNavigation } from "commons/enums";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AuthService } from "services";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { SettingsService } from "services/api/SettingsService.service";
import { AuthGuardService } from "services/guard/AuthGuard.service";
import { Login, Logout } from "@mui/icons-material";
import { MobileHeader } from "./mobileHamburger/MobileHeader";

export const Header = () => {
    const [researcherLogged, setResearcherLogged] = useState<boolean>(false);
    const [state, setState] = React.useState({
        right: false,
    });
    const [mobile, setMobile] = React.useState(false);

    const getUser = async () => {
        const isResearcherLoggedIn =
            AuthGuardService.getInstance().isResearcherLoggedIn();
        setResearcherLogged(isResearcherLoggedIn);
    };

    // const getSiteSettings = async () => {
    //     await SettingsService.getInstance().getSiteSettings();
    // };

    const toggleDrawer =
        (anchor: string = "right", open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setState({ ...state, [anchor]: open });
        };

    useEffect(() => {
        getUser();
        // getSiteSettings();
        window.innerWidth < 640 ? setMobile(true) : setMobile(false);
    }, []);

    const history: NavigateFunction = useNavigate();
    return (
        <Grid
            container
            spacing={0}
            alignItems={"center"}
            justifyContent={"center"}
            paddingY={2}
            display={"flex"}
            flexWrap={"wrap"}
            xs={12}
            className="header_container"
        >
            <Grid
                xs={12}
                {...(mobile ? {} : {sm:6})}
                container
                direction="row"
                justifyContent="flex-start"
            >
                <Grid
                    container
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent={mobile ? "center" : "flex-start"}
                    paddingLeft={mobile ? 0 : 12}
                    className="links_container"
                >
                    <Link
                        className="logo-wrapper"
                        onClick={() => {
                            history(PageNavigation.HOME);
                        }}
                        paddingRight={3}
                    >
                        <img
                            src="images/logo.svg"
                            alt="logo"
                            className="logo"
                        />
                    </Link>
                    <Link>
                        <img
                            src="images/payresearchcolor.svg"
                            alt="sublogo"
                            className="sub-logo"
                        />
                    </Link>
                    {mobile ? (
                        <MobileHeader
                            researcherLogged={researcherLogged}
                        ></MobileHeader>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>

            {mobile ? (
                ""
            ) : (
                <Grid
                    xs={11}
                    sm={6}
                    container
                    direction="row"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        xs={12}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        paddingRight={12}
                        className="register_login"
                    >
                        {!researcherLogged ? (
                            <Button
                                onClick={() => {
                                    history(PageNavigation.REGISTER);
                                }}
                            >
                                <span className="register">
                                    {PageGeneralTexts.REGISTER_HEADER}
                                </span>
                            </Button>
                        ) : (
                            ""
                        )}

                        <Link
                            paddingLeft={4}
                            className="log"
                            onClick={() => {
                                if (researcherLogged) {
                                    AuthService.getInstance().logout();
                                }
                                else {
                                    history(PageNavigation.LOGIN);
                                }
                            }}
                        >
                            <Button
                                className="btn-bg"
                                startIcon={
                                    researcherLogged ? (
                                        <Logout style={{ fontSize: "28px" }} />
                                    ) : (
                                        <Login style={{ fontSize: "28px" }} />
                                    )
                                }
                                size="large"
                            >
                                {researcherLogged ? (
                                    <p className="log">
                                        {PageGeneralTexts.LOGOUT_HEADER}
                                    </p>
                                ) : (
                                    <p className="log">
                                        {PageGeneralTexts.LOGIN}
                                    </p>
                                )}
                            </Button>
                        </Link>

                        {researcherLogged ? (
                            <Button
                                className="profile"
                                onClick={() => {
                                    history(PageNavigation.PROFILE);
                                }}
                            >
                                <img
                                    style={{
                                        width: "100%",
                                        backgroundSize: "cover",
                                    }}
                                    src="images/profilecircle.svg"
                                    alt="banner"
                                />
                            </Button>
                        ) : (
                            ""
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
