import axios from "axios";
import { RequestEnum, Routes, StorageKeys } from "commons/enums";
import { ErrorResponse } from "commons/interfaces";
import { environment } from "environments/Environment";
import { AuthTokens } from "Models/AuthTokens.model";
import { AuthUser } from "Models/AuthUser.model";
import { RedirectErrors } from "services/errors/RedirectErrors.service";
import { LocalStorageService } from "services/LocalStorage.service";
import GlobalApiService from "./GlobalApi.service";
import { OTP, ValidatePhone } from "commons/types";

export class AuthService {
    private static instance: AuthService;
    private localStorageService = new LocalStorageService();

    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthService();
        }
        return this.instance;
    }
    async validatePhone(authUser: AuthUser): Promise<boolean> {
        const loginResponse: AuthTokens | ErrorResponse | string =
            await GlobalApiService.generalRequest<AuthTokens>(
                RequestEnum.Get,
                `${Routes.GET_VALIDATE_PHONE}?phone=${authUser.phone}`,
                []
            );
        if (typeof loginResponse === "object" && "OTPToken" in loginResponse) {
            this.localStorageService.setToken(
                StorageKeys.OTPToken,
                loginResponse.OTPToken
            );
            return true;
        } else if (typeof loginResponse === "string") {
            console.error(loginResponse);
            return false;
        } else if (typeof loginResponse === "object") {
            console.error(loginResponse);
            return false;
        }
        // TODO: if otp was not required handle local login
        return false;
    }

    async validatePhoneBeforeOTP(
        authUser: AuthUser
    ): Promise<ValidatePhone | boolean> {
        const loginResponse: ValidatePhone | ErrorResponse | string =
            await GlobalApiService.generalRequest<ValidatePhone>(
                RequestEnum.Get,
                `${Routes.GET_VALIDATE_PHONE_BEFORE_OTP}?phone=${authUser.phone}`,
                []
            );
        let res: ValidatePhone | ErrorResponse | string;
        if (typeof loginResponse === "object" && "status" in loginResponse) {
            res = loginResponse;
            return res;
        } else {
            console.error(loginResponse);
            return false;
        }
    }

    async validateOTP(code: OTP): Promise<boolean> {
        try {
            const response = await axios.request<{ data: AuthTokens }>({
                method: RequestEnum.Post,
                url: `${environment.API_URL}${Routes.POST_OTP}`,
                headers: this.getByHeaderToken(StorageKeys.OTPToken),
                data: code,
            });

            if (response.data) {
                const authTokens = response.data.data;
                this.localStorageService.setToken(
                    StorageKeys.AccessToken,
                    authTokens.accessToken
                );
                this.localStorageService.setToken(
                    StorageKeys.RefreshToken,
                    authTokens.refreshToken
                );
                this.localStorageService.removeToken(StorageKeys.OTPToken);
                return true;
            }
            return false;
        } catch (error: any) {
            // RedirectErrors(
            //     error.response.data.code ?? "",
            //     this.localStorageService
            // );
            return false;
        }
    }

    async resendOTP(): Promise<boolean> {
        try {
            const response = await axios.request<{ data: AuthTokens }>({
                method: RequestEnum.Get,
                url: `${environment.API_URL}${Routes.GET_RESEND_OTP}`,
                headers: this.getByHeaderToken(StorageKeys.OTPToken),
            });
            if (typeof response === "object" && "data" in response) {
                const data = response.data.data;
                this.localStorageService.setToken(
                    StorageKeys.OTPToken,
                    data.OTPToken
                );
                return true;
            }
            return false;
        } catch (error: any) {
            RedirectErrors(
                error.response.data.code ?? "",
                this.localStorageService
            );
            return false;
        }
    }

    logout() {
        this.localStorageService.removeToken(StorageKeys.AccessToken);
        this.localStorageService.removeToken(StorageKeys.RefreshToken);
        this.localStorageService.removeToken(StorageKeys.OTPToken);
        window.location.reload();
    }

    public getByHeaderToken(
        tokenType: StorageKeys
    ): { ["Authorization"]: string } | {} {
        const token = this.localStorageService.getToken(tokenType);
        return token ? { Authorization: `${RequestEnum.Bearer} ${token}` } : {};
    }

    async refreshRT(reload: boolean = true): Promise<void> {
        try {
            const response = await axios.request({
                method: RequestEnum.Post,
                url: `${environment.API_URL}${Routes.POST_REFRESH_RT}`,
                headers: this.getByHeaderToken(StorageKeys.RefreshToken),
            });
            if (response.data) {
                const authTokens = new AuthTokens();

                authTokens.accessToken = response.data.data.accessToken;
                authTokens.refreshToken = response.data.data.refreshToken;

                this.localStorageService.setToken(
                    StorageKeys.AccessToken,
                    authTokens.accessToken
                );
                this.localStorageService.setToken(
                    StorageKeys.RefreshToken,
                    authTokens.refreshToken
                );
                if (reload) window.location.reload();
            }
        } catch (error) {
            try {
                // if refresh token is expired, let user know that the session timed out:
                const token = this.localStorageService.getToken(StorageKeys.RefreshToken);
                if (token) {
                    const {exp} = JSON.parse(atob( token.split(".")[1]) );
                    const expDate = new Date(exp * 1000);

                    if (expDate.valueOf() < Date.now()) {
                        // this will make the login page show a toast message:
                        this.localStorageService.setItemInLocalStorage('sessionExpired', '1');
                    }
                }
            }
            catch (err) {
                // failed to parse token, so we'll just logout
            }

            this.logout();
        }
    }
}
