import { ResearcherStatus } from "commons/enums";
import { Footer } from "components/footer/Footer";
import { Header } from "components/header/Header";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthGuardService } from "services/guard";

const GuestRouteGuard = () => {
    const isLoggedIn = AuthGuardService.getInstance().isResearcherLoggedIn();
    const hasOTP =
        AuthGuardService.getInstance().isResearcherOTPAuthenticated();
    const researcherPayload =
        AuthGuardService.getInstance().getCurrentResearcherPayload();

    // Access the route path
    const location = useLocation();
    const currentRoute = location.pathname;
    // these routes you cannot enter if the researcher is already logged in
    const ROUTES_THAT_NEED_STATUS_VALIDATION = ["/login"];

    if (ROUTES_THAT_NEED_STATUS_VALIDATION.includes(currentRoute)) {
        if (isLoggedIn) {
            if (
                researcherPayload.status === ResearcherStatus.APPROVED_PAID ||
                researcherPayload.status ===
                    ResearcherStatus.APPROVED_PAID_NOT_ACTIVE
            ) {
                return (
                    <>
                        <Navigate to="/profile" replace={true}></Navigate>
                    </>
                );
            } else {
                return (
                    <>
                        <Navigate to="/purchase" replace={true}></Navigate>
                    </>
                );
            }
        } else if (hasOTP && !isLoggedIn) {
            return (
                <>
                    <Navigate to="/otp" replace={true}></Navigate>
                </>
            );
        }
    }

    return (
        <>
            <Header></Header>
            <Outlet />
            <Footer></Footer>
        </>
    );
};

export default GuestRouteGuard;
