import { PageGeneralTexts, ToasterMessagesEnums } from "commons/enums";
import { Researcher } from "Models";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { VirtualMachineService } from "services/api/VirtualMachineService.service";
import "./VirtualLabRoom.scss";
import { Button, CardMedia, Grid, Typography } from "@mui/material";

export const VirtualLabRoom = ({ profile }: { profile: Researcher }) => {
    const [csvDataState, setCSVDataState] = useState("");
    const [fileNameState, setFileNameState] = useState(
        PageGeneralTexts.VIRTUAL_LAB_ROOM_EXPORT
    );

    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);

    const getTransactionsExport = async () => {
        try {
            const result: string =
                await VirtualMachineService.getInstance().getExportData();
            setCSVDataState(result);
            toast.success(ToasterMessagesEnums.CSV_DOWNLOAD);
        } catch (error) {
            console.error(error);
            toast.error(ToasterMessagesEnums.FAILED_CSV_DOWNLOAD);
        }
    };

    useEffect(() => {
        if (csvDataState) {
            csvLinkRef?.current?.link.click();
        }
    }, [csvDataState]);

    return (
        <>
            <Grid
                container
                display={"flex"}
                direction={"row"}
                justifyContent={"space-between"}
                paddingY={10}
                className="virtualLab-room"
            >
                <Grid
                    className="vm-title"
                    item
                    xs={12}
                    md={4.2}
                    marginLeft={"5%"}
                >
                    <Typography
                        variant="body1"
                        width={"60%"}
                        margin={0}
                        lineHeight={"70px"}
                        color={"#2457A0"}
                        textAlign={"left"}
                        fontWeight={700}
                        className="vmr_title"
                    >
                        {PageGeneralTexts.VIRTUAL_LAB_ROOM}
                    </Typography>
                </Grid>
                <Grid
                    container
                    xs={12}
                    md={6.4}
                    margin={0}
                    marginRight={"5%"}
                    display={"flex"}
                    direction={"row"}
                    justifyContent={"end"}
                    className="vm-lab"
                >
                    <Grid
                        container
                        display={"flex"}
                        direction={"row"}
                        xs={11}
                        md={10}
                        sx={{ backgroundColor: "#F1F9FE" }}
                        paddingX={5}
                        position={"relative"}
                        className="vm-lab-grid"
                    >
                        <Grid
                            container
                            xs={12}
                            sx={{ borderBottom: "1px solid #2457A0" }}
                        >
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={700}
                                    className="vrm_detail"
                                >
                                    {PageGeneralTexts.ID}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={500}
                                    className="vrm_detail"
                                >
                                    {profile?.virtualMachine?.name}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            sx={{ borderBottom: "1px solid #2457A0" }}
                        >
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={700}
                                    className="vrm_detail"
                                >
                                    {PageGeneralTexts.TYPE}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={500}
                                    className="vrm_detail"
                                >
                                    {profile?.virtualMachine?.type}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            xs={12}
                            sx={{ borderBottom: "1px solid #2457A0" }}
                        >
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={700}
                                    className="vrm_detail"
                                >
                                    {PageGeneralTexts.NAME}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={500}
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                    style={{ whiteSpace: "nowrap" }}
                                    className="vrm_detail"
                                >
                                    {profile?.virtualMachine?.resourceId}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={700}
                                    className="vrm_detail"
                                >
                                    {PageGeneralTexts.SIZE}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="body1"
                                    fontSize={"24px"}
                                    margin={0}
                                    color={"#2457A0"}
                                    textAlign={"left"}
                                    marginY={2.5}
                                    paddingLeft={10}
                                    fontWeight={500}
                                    className="vrm_detail"
                                >
                                    {profile?.virtualMachine?.size}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
