export enum ResearcherStatus {
    /**
     *  PENDING = the researcher has requested to be a researcher so he/she is a potential researcher
     *  DECLINED = the researchers request was declined by the admin and he/she can't be a researcher (data will be shown potential researcher table)
     *  APPROVED_NOT_PAID = the researchers request was accepted by the admin but the researcher hasn't made his/her first payment (data will be shown potential researcher table)
     *  APPROVED_PAID = the researcher has paid for the VM service and is in subscribed kind status (data will be shown in researchers table)
     *  APPROVED_PAID_NOT_ACTIVE = the researcher has paid in the past for the VM service and now he/she has stopped the subscription (data will be shown in researchers table)
     *  APPROVED_TERMINATED = this status occurs when an operator or admin ends the service for the researcher it means he was active but his account is now terminated
     */

    PENDING = 'PENDING', 
    APPROVED_NOT_PAID = 'APPROVED_NOT_PAID',
    DECLINED = 'DECLINED',
    APPROVED_PAID = 'APPROVED_PAID',
    APPROVED_PAID_NOT_ACTIVE = 'APPROVED_PAID_NOT_ACTIVE',
    APPROVED_TERMINATED = 'APPROVED_TERMINATED',
}
