import { GlobalErrors } from "commons/I18n";

const ERROR_GENERAL = 'gn';
const ERROR_LOGIN = 'lg';
const ERROR_REGISTER = 'mg';
const ERROR_RESEARCHER = 'gr';

export const ErrorManager = (err: any, location: string, codeNumber: number) => {
    const messages: string[] = [].concat(err.message);

    if(location === ERROR_REGISTER){
        switch (codeNumber) {
            case 2:
                return 'Researcher email or phone already exists';
            }
    }
        
    if (location === ERROR_LOGIN) {
        switch (codeNumber) {
        case 1:
            return '';
        }
    }
    
     if (location === ERROR_GENERAL) {
        switch (codeNumber) {
        case 1:
            if (messages.includes('businessId must be longer than or equal to 2 characters')) {
                return 'Invalid Business ID';
            }
            else if (messages.includes('Phone number must meet E.164 international standard')) {
                return 'Invalid phone number';
            }
        }
    }

    if (location === ERROR_RESEARCHER) {
        switch (codeNumber) {
            case 9: return 'Researcher email already exists';
            case 10: return 'Researcher phone already exists';
        }
    }

    //should not reach this stage but here in case an error was not handled with a switch.
    return GlobalErrors.GeneralError;
}