import { ResearcherStatus } from "commons/enums";
import { Footer } from "components/footer/Footer";
import { Header } from "components/header/Header";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthGuardService } from "services/guard";

const AuthRouteGuard = () => {
    const hasOTP =
        AuthGuardService.getInstance().isResearcherOTPAuthenticated();
    const loggedIn = AuthGuardService.getInstance().isResearcherLoggedIn();
    const researcherPayload =
        AuthGuardService.getInstance().getCurrentResearcherPayload();
    // Access the route path
    const location = useLocation();
    const currentRoute = location.pathname;
    const ROUTES_ALLOWED_ONLY_FOR_APPROVED_NOT_PAID = ["/payment", "/purchase"];
    const ROUTES_ALLOWED_ONLY_FOR_APPROVED = ["/profile"];

    if (hasOTP && !loggedIn) {
        return (
            <>
                <Navigate to="/otp" replace={true}></Navigate>
            </>
        );
    } else if (!hasOTP && !loggedIn) {
        return (
            <>
                <Navigate to="/login" replace={true}></Navigate>
            </>
        );
    } else if (
        ROUTES_ALLOWED_ONLY_FOR_APPROVED_NOT_PAID.includes(currentRoute)
    ) {
        if (researcherPayload.status !== ResearcherStatus.APPROVED_NOT_PAID) {
            return (
                <>
                    <Navigate to="/" replace={true}></Navigate>
                </>
            );
        }
    } else if (
        ROUTES_ALLOWED_ONLY_FOR_APPROVED.includes(currentRoute) &&
        researcherPayload.status === ResearcherStatus.APPROVED_NOT_PAID
    ) {
        return (
            <>
                <Navigate to="/purchase" replace={true}></Navigate>
            </>
        );
    }
    return (
        <>
            <Header></Header>
            <Outlet />
            <Footer></Footer>
        </>
    );
};

export default AuthRouteGuard;
