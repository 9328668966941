import {
    Button,
    CardMedia,
    Grid,
    Tab,
    TextField,
    Typography,
} from "@mui/material";
import {
    ReviewResponseType,
    ReviewType,
    TerminationConfirmation,
} from "commons/types";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import "./RateUs.scss";
import { ResearcherService } from "services/api/ResearcherService.service";
import { toast } from "react-toastify";
import { PageGeneralTexts } from "commons/enums";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Loader } from "components/Loader/Loader";

export const RateUs = ({ profile, toggle }: TerminationConfirmation) => {
    const [toggleSuccess, setToggleSuccess] = useState(false);
    const [value, setValue] = useState<number | null>(0);
    const [title, setTitle] = useState("");
    const [review, setReview] = useState("");
    const [loaderState, setLoaderState] = useState(false);

    const StyledRating = styled(Rating)({
        "& .MuiRating-iconFilled": {
            color: "#2457A0",
        },
        "& .MuiRating-iconHover": {
            color: "#2457A0",
        },
    });

    const sendReview = async () => {
        setLoaderState(true);
        let reviewObj = {} as ReviewType;
        reviewObj.rating = value ? value : 0;
        reviewObj.title = title;
        reviewObj.content = review;

        const result: ReviewResponseType | string =
            await ResearcherService.getInstance().submitReview(
                profile._id,
                reviewObj
            );
        if (typeof result === "object") {
            toast.success(result.message);
            toggle();
        }
        if (typeof result === "string") {
            toast.error(result);
        }
        setLoaderState(false);
    };

    const reviewSchema = yup.object().shape({
        title: yup.string().required(),
        review: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(reviewSchema) });

    const onSubmit = handleSubmit(async () => {
        console.log("submit", errors);
        if (!errors.title || !errors.review) {
            sendReview();
        }
    });

    return (
        <>
            <Grid container className="rating_grid">
                <Button
                    onClick={toggle}
                    sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        minHeight: "35px",
                        minWidth: "35px",
                        backgroundColor: "transparent",
                        border: "0",
                        width: "36px",
                    }}
                >
                    <CardMedia
                        sx={{
                            backgroundColor: "transparent",
                            width: "36px",
                        }}
                        component="img"
                        height="auto"
                        image="images/closecircle.svg"
                        alt="banner"
                    />
                </Button>
                <Grid item xs={12} marginBottom={3} className="title_grid">
                    <Typography
                        variant="body1"
                        fontSize={"24px"}
                        margin={0}
                        marginX={0}
                        marginBottom={0}
                        color={"#666"}
                        width={"100%"}
                        textAlign={"left"}
                        fontWeight={600}
                        className="rate_title"
                    >
                        {PageGeneralTexts.RATE_US}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    marginBottom={3}
                    className="stars_rate"
                >
                    <StyledRating
                        className="rating-section"
                        name="simple-controlled"
                        value={value}
                        precision={1}
                        size="large"
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    />
                </Grid>
                <form onSubmit={onSubmit} style={{ width: "100%" }}>
                    <Grid item xs={12} display={"flex"} direction={"column"}>
                        <TextField
                            variant="outlined"
                            className={errors.title ? "error" : ""}
                            minLength={2}
                            placeholder="Title"
                            id="title"
                            {...register("title")}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setTitle(e.target.value);
                            }}
                            sx={{ marginBottom: 2, backgroundColor: "white" }}
                        />
                        <TextField
                            variant="outlined"
                            className={errors.review ? "error" : ""}
                            minLength={2}
                            multiline
                            rows={3}
                            placeholder="Write your review"
                            id="review"
                            {...register("review")}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setReview(e.target.value);
                            }}
                            sx={{ marginBottom: 2, backgroundColor: "white" }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        marginTop={2}
                        display={"flex"}
                        justifyContent={"start"}
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                backgroundColor: "#2457A0",
                                paddingX: "45px",
                                paddingY: "7px",
                                fontSize: "20px",
                                color: "white",
                                borderRadius: "45px",
                                marginTop: "0",
                                marginBottom: "0",
                                textTransform: "none",
                            }}
                            className="submit_rate"
                        >
                            {PageGeneralTexts.SUBMIT}
                        </Button>
                    </Grid>
                </form>
                {loaderState ? (
                    <Grid
                        container
                        position={"fixed"}
                        left={"30px"}
                        top={0}
                        width={"100%"}
                        height={"100%"}
                    >
                        <Loader loaderState={loaderState}></Loader>
                    </Grid>
                ) : (
                    ""
                )}
            </Grid>
        </>
    );
};
